import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  notification,
  Row,
  Spin,
  Upload,
} from 'antd';
import dayjs from 'dayjs';
import { parse } from 'qs';
import React, { useEffect, useRef, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useFetch } from '../../../hooks/useRequest';
import {
  createPspContent,
  fetchPspContent,
  updatePspContent,
} from '../../../services/pspService';
import AntTinymceInput from '../../components/antTinymceInput';
import ElementLoading from '../../components/elementLoading';
import WindowHeader from '../../components/windowHeader';
import useUploadFile from '../../util/useUploadFile';

const PspContentWindow = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [windowId, setWindowId] = useState('');
  const [id, setid] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [description, setDescription] = useState('');
  const [form] = Form.useForm();
  const [thumbnailLoading, setThumbnailLoading] = useState(false);

  const [distinctFileInputEvent, setDistinctFileInputEvent] = useState(null);
  const {
    publicUrl: distinctPublicUrl,
    fileId: distinctFileId,
    done: distinctImageUploadDone,
    imageUpload: distinctImageUpload,
    initialize: distinctUploadInitialize,
  } = useUploadFile(distinctFileInputEvent, 'PSP_CONTENTS_THUMBNAIL');
  const completeUploadImg = useRef(false);

  const uploadThumbImg = async (e) => {
    if (e !== undefined && e.file.status) {
      setDistinctFileInputEvent(e);
      distinctImageUpload(e);
      setThumbnailLoading(true);
      completeUploadImg.current = false;
    }
  };

  useEffect(() => {
    if (completeUploadImg.current) return;
    if (distinctImageUploadDone && distinctPublicUrl) {
      notification.success({
        message: '이미지 업로드에 성공하였습니다.',
        description: '썸네일 등록에 성공하였습니다.',
      });

      form.setFieldsValue({ thumbnailId: distinctFileId });
      setThumbnailLoading(false);
      completeUploadImg.current = true;
    }
  }, [distinctImageUploadDone, distinctPublicUrl]);

  const { data: PspContentData, call: fetchPspContentData } = useFetch(
    null,
    fetchPspContent,
    id,
  );

  const { search } = useLocation();

  const setupBeforeUnloadListener = (data) => {
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      return window?.opener?.postMessage(data, '/');
    });
  };

  useEffect(() => {
    const params = parse(search, {
      ignoreQueryPrefix: true,
    });
    setWindowId(params.id ? params.id : params.new);
    if (params.id) {
      setid(params.id);
    }

    setIsLoading(false);
    setupBeforeUnloadListener(`close ${windowId}`);
  }, [search, windowId]);

  useEffect(() => {
    if (id) {
      fetchPspContentData();
    }
  }, [fetchPspContentData, id]);

  useEffect(() => {
    if (PspContentData) {
      form.setFieldsValue({
        ...PspContentData,
        createdAt: dayjs(PspContentData.createdAt),
      });
      setDescription(PspContentData.description);
      distinctUploadInitialize(PspContentData.thumbnail?.url);
    }
  }, [PspContentData, form]);

  const handleSubmit = async (values) => {
    const REQ_BODY = {
      ...values,
      createdAt: values.createdAt.format('YYYY-MM-DD'),
      thumbnailId: distinctFileId || undefined,
    };

    if (window.confirm(`저장하시겠습니까?`)) {
      if (id) {
        // patch
        const result = await updatePspContent(id, REQ_BODY);
        window?.opener?.postMessage(
          `contents ${result.id} ${JSON.stringify(result)}`,
          '/',
        );
      } else {
        // post
        REQ_BODY.type = 'patient';
        const result = await createPspContent(REQ_BODY);
        window?.opener?.postMessage(
          `contents ${result.id} ${JSON.stringify(result)}`,
          '/',
        );
      }
      alert('저장되었습니다.');
      window.close();
    }
  };

  const noop = () => {};

  const uploadProps = {
    customRequest: noop,
    onChange: uploadThumbImg,
    onPreview: noop,
  };

  const beforeUpload = (file) => {
    const isSupportedImageType = file.type === 'image/svg+xml';
    if (!isSupportedImageType) {
      notification.error({
        message: 'SVG 파일만 업로드 가능합니다.',
        description: '이미지 업로드를 다시 시도해주세요.',
      });
    }
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      notification.error({
        message: '이미지가 10MB보다 큽니다.',
        description: '이미지 업로드를 다시 시도해주세요.',
      });
    }
    return isSupportedImageType && isLt10M;
  };

  if (isLoading) return <ElementLoading type="운영 > PSP > 콘텐츠 등록" />;
  return (
    <>
      <WindowHeader title="운영 > PSP > 콘텐츠 등록" />
      <Row span={24} style={{ padding: 16 }}>
        <Col span={24}>
          <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            form={form}
            onFinish={handleSubmit}
            onFinishFailed={(error) => {
              notification.error({
                key: 'onFailed',
                message: '저장에 실패했습니다. 필수 항목을 확인해주세요.',
              });
            }}
            initialValues={{ createdAt: dayjs() }}
          >
            <Form.Item
              label="제목"
              name="title"
              rules={[
                {
                  required: true,
                  message: '약관명을 확인해주세요.',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="부제목"
              name="subTitle"
              rules={[
                {
                  required: true,
                  message: '약관명을 확인해주세요.',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="등록일"
              name="createdAt"
              rules={[{ required: true, message: '등록일을 확인해주세요.' }]}
            >
              <DatePicker format="YYYY-MM-DD" />
            </Form.Item>
            <Form.Item label="썸네일" tooltip="SVG 형태만 업로드 가능합니다.">
              <Upload
                {...uploadProps}
                listType="picture-card"
                showUploadList={false}
                beforeUpload={beforeUpload}
              >
                {distinctPublicUrl ? (
                  <img
                    src={distinctPublicUrl}
                    width="100%"
                    height="100%"
                    type="image/svg+xml"
                  />
                ) : (
                  <div>
                    {thumbnailLoading ? <LoadingOutlined /> : <PlusOutlined />}
                    <div
                      style={{
                        marginTop: 8,
                      }}
                    >
                      Upload
                    </div>
                  </div>
                )}
              </Upload>
            </Form.Item>
            <Form.Item
              label="내용"
              name="description"
              rules={[
                {
                  required: true,
                  message: '콘텐츠 내용을 확인해주세요.',
                },
              ]}
              className="treatmentContent"
            >
              <LoadingOverlay
                active={imageLoading}
                spinner={<Spin />}
                text={<p>이미지를 업로드 하는 중</p>}
              >
                <AntTinymceInput
                  content={description}
                  setContent={(value) => {
                    form.setFieldsValue({
                      description: value,
                    });
                    setDescription(value);
                  }}
                  setImageLoading={setImageLoading}
                />
              </LoadingOverlay>
            </Form.Item>
            <Form.Item
              wrapperCol={{
                offset: 4,
                span: 20,
              }}
            >
              <Button type="primary" htmlType="submit" style={{ width: 100 }}>
                저장
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default PspContentWindow;
