import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Upload, message, notification } from 'antd';
import { parse } from 'qs';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useFetch } from '../../../hooks/useRequest';
import { uploadFileWithSignedUrl } from '../../../services/fileUploadService';
import {
  fetchPAPDocumentData,
  fetchPapPostalUserDocuments,
  fetchPapUserRequest,
  updatePapUserRequest,
} from '../../../services/papService';
import WindowHeader from '../../components/windowHeader';

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

const PapPostalUserDocumentWindow = () => {
  const [form] = Form.useForm();
  const [currentAttributeId, setCurrentAttributeId] = useState(null);
  const [requestId, setRequestId] = useState(null);
  const [projectId, setProjectId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(false);
  const { search } = useLocation();
  const [allDocuments, setDocuments] = useState([]);
  const [photos, setPhotos] = useState({});

  const { data: attributes, call: fetchAttributes } = useFetch(
    null,
    fetchPapPostalUserDocuments,
    projectId,
  );

  const { data: userRequests, call: fetchUserRequests } = useFetch(
    null,
    fetchPapUserRequest,
    userId,
  );

  useEffect(() => {
    const params = parse(search, {
      ignoreQueryPrefix: true,
    });
    setRequestId(params.id);
    setProjectId(params.projectId);
    setUserId(params.userId);
  }, [search]);

  useEffect(() => {
    if (projectId && requestId) fetchAttributes();
  }, [projectId, requestId]);

  useEffect(() => {
    if (attributes && attributes.length > 0) {
      setCurrentAttributeId(attributes[0].id);
    }
  }, [attributes]);

  useEffect(() => {
    if (userId) fetchUserRequests();
  }, [fetchUserRequests, userId]);

  useEffect(() => {
    if (userRequests?.requests) {
      setDocuments(
        userRequests.requests.find((request) => request.id === requestId)
          ?.documents ?? [],
      );
    }
  }, [userRequests]);

  const handleSubmit = async (values) => {
    if (loading) return;
    setLoading(true);
    try {
      const postedDocumentsAllAttributes = await Promise.all(
        attributes.map(async (attribute) => {
          const defaultDocLength = allDocuments.filter(
            (doc) => doc.attributeId === attribute.id,
          ).length;
          const images = photos[attribute.id];
          if (Array.isArray(images)) {
            const postedDocuments = await Promise.all(
              images.map(async (image, i) => {
                const documentRes = await fetchPAPDocumentData(
                  requestId,
                  attribute.id,
                );
                if (
                  documentRes &&
                  documentRes.uploadUrl &&
                  image?.originFileObj
                ) {
                  const requestUploadParams = {
                    signedUrl: documentRes.uploadUrl,
                    originFileObj: image.originFileObj,
                  };

                  await uploadFileWithSignedUrl(requestUploadParams)
                    .then()
                    .catch((err) => {
                      throw err;
                    });

                  const extension =
                    image.type.split('/')[1] === 'jpeg'
                      ? 'jpg'
                      : image.type.split('/')[1];
                  return {
                    ...documentRes,
                    fileName: `${documentRes.fileName}_${
                      i + defaultDocLength + 1
                    }${extension ? `.${extension}` : ''}`,
                    attributeId: attribute.id,
                    isComplement: attribute.id === 'complement',
                    createdAt: new Date().toISOString(),
                  };
                }
              }),
            );
            return postedDocuments;
          }
          return [];
        }),
      );
      const documents = postedDocumentsAllAttributes.flat();
      const result = await updatePapUserRequest(requestId, {
        documents: [...allDocuments, ...documents],
      });
      alert('저장되었습니다.');
      window.close();
      window.opener.location.reload();
    } catch (error) {
      message.error('서류 업로드에 실패했습니다.');
    } finally {
      setLoading(false);
    }
  };

  const checkFileValidate = (file) => {
    const isValidFile = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isValidFile) {
      notification.error({
        message: 'JPG/PNG만 업로드 가능합니다.',
        description: '이미지 업로드를 다시 시도해주세요.',
      });
      return Upload.LIST_IGNORE;
    }
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      notification.error({
        message: '이미지가 10MB보다 큽니다.',
        description: '이미지 업로드를 다시 시도해주세요.',
      });
      return Upload.LIST_IGNORE;
    }
    return false;
  };

  const onDeleteDocument = (documentId) => {
    setDocuments((prev) => prev.filter((doc) => doc.id !== documentId));
  };

  const openImagePreview = (src) => {
    const imgWindow = window.open('', '_blank');

    if (imgWindow) {
      imgWindow.document.write(`
        <html>
          <head>
            <title>이미지 미리보기</title>
            <style>
              body {
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100vh;
                background-color: #000;
              }
              img {
                max-width: 100%;
                max-height: 100%;
              }
            </style>
          </head>
          <body>
            <img src="${src}" alt="미리보기 이미지" />
          </body>
        </html>
      `);
      imgWindow.document.close();
    } else {
      alert('팝업이 차단되었을 수 있습니다.');
    }
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    openImagePreview(src);
  };

  return (
    <>
      <WindowHeader title="서류 업로드" />
      <Row style={{ padding: 16 }}>
        <Col span={24}>
          <Form
            form={form}
            onFinish={handleSubmit}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
          >
            <Form.Item label="항목" name="selectedItem" required>
              <div>
                {attributes?.map((attribute) => (
                  <Button
                    key={attribute.id}
                    type={
                      currentAttributeId === attribute.id
                        ? 'primary'
                        : 'default'
                    }
                    onClick={() => setCurrentAttributeId(attribute.id)}
                  >
                    {attribute.title}
                  </Button>
                ))}
              </div>
            </Form.Item>

            {/* 선택된 항목에 대해서만 이미지 업로드 */}
            {attributes?.map((attribute) => {
              const defaultDocuments = allDocuments.filter(
                (d) => d.attributeId === attribute.id,
              );
              return (
                <Form.Item
                  label="서류 업로드"
                  required
                  valuePropName={attribute.id}
                  getValueFromEvent={normFile}
                  name={attribute.id}
                  tooltip="파일명은 업로드 항목에 맞춰 자동으로 변환되어 업로드 됩니다. "
                  key={attribute.id}
                  style={{
                    display:
                      currentAttributeId === attribute.id ? 'block' : 'none',
                  }}
                >
                  <div style={{ display: 'flex', gap: 8 }}>
                    {defaultDocuments?.map((doc) => (
                      <div
                        key={doc.id}
                        style={{
                          width: 102,
                          height: 102,
                          background: '#f0f0f0',
                          flexShrink: 0,
                          border: '1px solid #d9d9d9',
                          marginTop: 1,
                          position: 'relative',
                        }}
                        className="hover-container"
                      >
                        <img
                          src={doc.uri}
                          alt={doc.fileName}
                          style={{
                            fit: 'cover',
                            width: '100%',
                            height: '100%',
                          }}
                        />
                        <div
                          className="hover-button"
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            opacity: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            transition: 'opacity 0.2s',
                            background: 'rgba(0, 0, 0, 0.4)',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 10,
                          }}
                        >
                          <span style={{ color: 'white', fontSize: 12 }}>
                            {doc.fileName}
                          </span>
                          <div style={{ display: 'flex', gap: 4 }}>
                            <Button
                              icon={
                                <SearchOutlined
                                  style={{ verticalAlign: 'baseline' }}
                                />
                              }
                              type="default"
                              size="small"
                              onClick={() => openImagePreview(doc.uri)}
                            />
                            <Button
                              type="default"
                              size="small"
                              onClick={() => onDeleteDocument(doc.id)}
                            >
                              삭제
                            </Button>
                          </div>
                        </div>
                      </div>
                    ))}
                    <Upload
                      listType="picture-card"
                      beforeUpload={checkFileValidate}
                      onChange={({ fileList }) =>
                        setPhotos((prev) => ({
                          ...prev,
                          [attribute.id]: fileList,
                        }))
                      }
                      onPreview={onPreview}
                    >
                      <button
                        style={{
                          color: 'inherit',
                          cursor: 'inherit',
                          border: 0,
                          background: 'none',
                        }}
                        type="button"
                      >
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>Upload</div>
                      </button>
                    </Upload>
                  </div>
                </Form.Item>
              );
            })}

            <Form.Item wrapperCol={{ offset: 4 }}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={loading || !currentAttributeId}
              >
                저장
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default PapPostalUserDocumentWindow;
