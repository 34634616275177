import { SearchOutlined } from '@ant-design/icons';
import { Button, Divider, Input, Select, Space, Table } from 'antd';
import moment from 'moment';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import PAGE_URL from '../../../assets/pageUrl';
import useWindow from '../../../hooks/useWindow';
import { fetchPapPostalUsers } from '../../../services/papService';
import { NO_MATCHING_DATA_FOUND } from '../../../services/utils';
import { diseaseTypeCreators } from '../../../store/reducers/diseaseType.reducer';
import { paginationCreators } from '../../../store/reducers/pagination.reducer';
import {
  HEALTH_PROFILE_ACCOUNT_TYPES,
  HEALTH_PROFILE_GENDER,
} from '../../../util/healthProfileConstants';
import * as listMap from '../../../util/listMap';
import { NEW } from '../../../window/util/utils';

const TagWrapper = ({ tag }) => {
  return (
    <div
      className="tagWrapper"
      key={tag}
      style={{
        color: '#333',
      }}
    >
      {tag}
    </div>
  );
};

const PapPostalList = ({ papProjects }) => {
  const [papRequests, setPapRequests] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { createWindow } = useWindow();
  const history = useHistory();
  const { Search } = Input;
  const confirmRef = useRef(() => {});

  const { papPostalPagination, diseaseTypeInfo } = useSelector((state) => {
    return {
      papPostalPagination: state.paginationReducer.papPostalPagination,
      diseaseTypeInfo: state.diseaseTypeReducer.diseaseTypes.data ?? [],
    };
  });

  const diseaseTypeList = useMemo(() => {
    const arr = [
      {
        label: '전체 질환',
        value: 'all',
      },
      ...diseaseTypeInfo?.map((d) => ({
        label: d.krName,
        value: d.id,
      })),
    ];
    return arr;
  }, [diseaseTypeInfo]);

  const projects = useMemo(() => {
    return papProjects.map((d) => ({
      label: d.title,
      value: d.id,
    }));
  }, [papProjects]);

  const fetchList = useCallback(async () => {
    setLoading(true);

    try {
      const result = await fetchPapPostalUsers();
      dispatch(
        paginationCreators.setValue(
          (papPostalPagination.total = result.length),
        ),
      );
      setPapRequests(
        result.map((u) => ({
          ...u,
          patientNumbers: u.projects.map((p) => p.patientNumber),
        })),
      );
      dispatch(diseaseTypeCreators.fetchAllDiseaseTypes.request());
    } catch (e) {
      if (e.data?.status === NO_MATCHING_DATA_FOUND) {
        alert('데이터를 불러올수 없습니다.');
      }
    } finally {
      setLoading(false);
    }
  }, [dispatch, history, papPostalPagination, diseaseTypeCreators]);

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  const handleMoveDetailPage = (record) => {
    history.push(`/${PAGE_URL.PAP_POSTAL_USER_AUDIT_DETAIL}`, {
      userId: record?.id ?? '',
      type: 'pap',
    });
  };

  const handleCreateDetailWindow = () => {
    createWindow({
      id: `${NEW}${Date.now()}`,
      dataType: 'papPostalUser',
    });
  };

  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };
  const handleReset = (clearFilters) => {
    clearFilters();
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys[0], confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys[0], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      (record[dataIndex] ? record[dataIndex].toString() : '').includes(value),
  });

  const columns = [
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name',
      width: 200,
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => {
            handleMoveDetailPage(record);
          }}
          style={{
            width: '100%',
            textAlign: 'left',
            whiteSpace: 'normal',
            wordBreak: 'break-all',
          }}
        >
          {text ?? record.socialUid}
        </Button>
      ),
      sorter: (a, b) => a.name?.localeCompare(b.name),
      filteredValue: papPostalPagination.filter?.name || null,
      ...getColumnSearchProps('name'),
    },
    {
      title: '연락처',
      dataIndex: 'phone',
      key: 'phone',
      render: (value) => (value ? <div>{value}</div> : '-'),
      sorter: {
        compare: (a, b) => a?.phone?.localeCompare(b?.phone),
      },
      filteredValue: papPostalPagination.filter?.phone || null,
      ...getColumnSearchProps('phone'),
    },
    {
      title: '생년월일',
      dataIndex: 'birthday',
      key: 'birthday',
      render: (value) =>
        value ? <div>{moment(value).format('YYYY.MM.DD')}</div> : '-',
      sorter: (a, b) => moment(a.birthday || 0).diff(b.birthday || 0),
      filteredValue: papPostalPagination.filter?.birthday || null,
      ...getColumnSearchProps('birthday'),
    },
    {
      title: '성별',
      dataIndex: 'gender',
      key: 'gender',
      filteredValue: papPostalPagination.filter?.gender || [],
      onFilter: (value, record) => record.gender === value,
      filters: Object.entries(HEALTH_PROFILE_GENDER).map(([key, value]) => ({
        text: value,
        value: key,
      })),
      render: (gender) => HEALTH_PROFILE_GENDER[gender] ?? '-',
    },
    {
      title: '질환',
      dataIndex: 'diseaseType',
      key: 'diseaseType',
      render: (disease) => <TagWrapper tag={disease.name} />,
      filters: diseaseTypeList,
      filteredValue: papPostalPagination.filter.diseaseTypes || [],
      onFilter: (value, record) => record.diseaseType.id === value,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div
          style={{
            padding: 8,
          }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <Select
            style={{
              marginRight: 8,
              width: 250,
            }}
            placement="bottomLeft"
            defaultValue={papPostalPagination.filter.diseaseTypes ?? null}
            value={selectedKeys}
            showSearch
            mode="multiple"
            placeholder="질환을 선택하세요"
            options={diseaseTypeList}
            filterOption={(input, { label }) =>
              label.toLowerCase().includes(input.toLowerCase())
            }
            onChange={(e) => setSelectedKeys(e)}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{
                width: 90,
              }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilters()}
              size="small"
              style={{
                width: 90,
              }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
    },
    {
      title: '계정 유형',
      dataIndex: 'accountType',
      key: 'accountType',
      sorter: {
        compare: (a, b) => a?.accountType?.localeCompare(b?.accountType),
      },
      filters: listMap.relationshipFilter,
      filteredValue: papPostalPagination.filter?.accountType || null,
      render: (accountType) => HEALTH_PROFILE_ACCOUNT_TYPES[accountType] ?? '-',
      onFilter: (value, record) => record.accountType.includes(value),
    },
    {
      title: '14세 미만',
      dataIndex: 'isUnder14',
      key: 'isUnder14',
      render: (isUnder14) => <>{isUnder14 ? 'O' : 'X'}</>,
      filters: [
        {
          text: 'O',
          value: true,
        },
        {
          text: 'X',
          value: false,
        },
      ],
      filteredValue: papPostalPagination.filter?.isUnder14 || null,
      onFilter: (value, record) => record.isUnder14 === value,
    },
    {
      title: '신청 일시(우편 접수 일시)',
      key: 'registeredAt',
      dataIndex: 'registeredAt',
      render: (value) =>
        value ? <div>{moment(value).format('YYYY.MM.DD HH:mm')}</div> : '-',
      sorter: (a, b) => moment(a.registeredAt || 0).diff(b.registeredAt || 0),
    },
    {
      title: '신청한 PAP',
      dataIndex: 'projects',
      key: 'projects',
      render: (values) => (
        <div>
          {values?.map((d, i) => (
            <div key={d.id}>
              {d.title}
              {i !== values.length - 1 && ','}
            </div>
          ))}
        </div>
      ),
      filters: projects,
      filteredValue: papPostalPagination.filter.projects || null,
      onFilter: (value, record) => {
        const isProjectMatched = record.projects
          .map((d) => d.id)
          .includes(value);
        const isAllProjectMatched =
          value === 'all' && record.projects.length === 0;
        return isProjectMatched || isAllProjectMatched;
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div
          style={{
            padding: 8,
          }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <Select
            style={{
              marginRight: 8,
              width: 250,
            }}
            placement="bottomLeft"
            defaultValue={papPostalPagination.filter.projects ?? null}
            value={selectedKeys}
            showSearch
            mode="multiple"
            placeholder="프로젝트를 선택하세요"
            options={projects}
            filterOption={(input, { label }) =>
              label.toLowerCase().includes(input.toLowerCase())
            }
            onChange={(e) => setSelectedKeys(e)}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{
                width: 90,
              }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilters()}
              size="small"
              style={{
                width: 90,
              }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
    },
    {
      title: '환자 번호',
      dataIndex: 'patientNumbers',
      key: 'patientNumbers',
      sorter: {
        compare: (a, b) =>
          a?.patientNumbers
            ?.join(',')
            ?.localeCompare(b?.patientNumbers?.join(',')),
      },
      filteredValue: papPostalPagination.filter?.patientNumbers || null,
      onFilter: (value, record) =>
        record.patientNumbers?.some((p) => p.includes(value)),
      render: (values) => (
        <div>
          {values?.map((d, i) => (
            <div key={d}>
              {d}
              {i !== values.length - 1 && ','}
            </div>
          ))}
        </div>
      ),
      ...getColumnSearchProps('patientNumbers'),
    },
  ];

  const onTableChange = (pagination, filters, sorter, extra) => {
    dispatch(
      paginationCreators.setValue(
        (papPostalPagination.filter = filters),
        (papPostalPagination.sorter = sorter),
        (papPostalPagination.page = pagination?.current),
        (papPostalPagination.total = extra?.currentDataSource?.length),
      ),
    );
  };

  return (
    <div>
      <Button type="primary" onClick={handleCreateDetailWindow}>
        우편 접수 환자 추가
      </Button>
      <Search
        placeholder="검색어를 입력해주세요."
        allowClear
        className="searchStyle"
        onSearch={(value) => {
          confirmRef.current(value);
          dispatch(
            paginationCreators.setValue(
              (papPostalPagination.filter.keyword =
                value.length > 0 ? value : null),
            ),
          );
        }}
        defaultValue={papPostalPagination.filter.keyword}
      />
      <div className="searchResult">{`검색결과 ${papPostalPagination.total}개`}</div>
      <Divider />
      <Table
        loading={loading}
        pageUpdate={page}
        setPageUpdate={setPage}
        columns={columns}
        dataSource={papRequests}
        size="small"
        bordered
        onChange={onTableChange}
        rowKey={(record) => record.id}
        pagination={{
          pageSize: 20,
          showSizeChanger: true,
        }}
      />
    </div>
  );
};

export default PapPostalList;
