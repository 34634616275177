import {
  DownloadOutlined,
  DownOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Divider,
  Dropdown,
  Input,
  Layout,
  Menu,
  message,
  Modal,
  notification,
  Space,
  Table,
  Typography,
} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import moment from 'moment';
import { parse } from 'qs';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import PAGE_URL from '../../../assets/pageUrl';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import useWindow from '../../../hooks/useWindow';
import {
  deletePapProjectUserRequest,
  downloadPapDocuments,
  downloadPapProjectFinanceExcel,
  downloadPapProjectReportExcel,
  fetchPapProject,
  fetchPapProjectRequests,
  updatePapUserRequest,
} from '../../../services/papService';
import { NO_MATCHING_DATA_FOUND } from '../../../services/utils';
import { paginationCreators } from '../../../store/reducers/pagination.reducer';
import { PAP_REQUEST_STATUS } from '../../../util/papConstants';
import { NEW } from '../../../window/util/utils';

const PapAuditList = () => {
  const [papProject, setPapProject] = useState(null);
  const [papRequests, setPapRequests] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [downloadingIds, setDownloadingIds] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { search } = useLocation();
  const history = useHistory();
  const confirmRef = useRef(() => {});
  const { findWindowById, createWindow, destroyWindowById } = useWindow();

  const { papRequestPagination } = useSelector((pagingState) => {
    return {
      papRequestPagination: pagingState.paginationReducer.papProjectPagination,
    };
  });

  const fetchList = useCallback(async () => {
    setLoading(true);
    const params = parse(search, {
      ignoreQueryPrefix: true,
    });
    try {
      if (!params.id) throw new Error('invalid project id');
      const result = await fetchPapProjectRequests(params.id);
      const filteredResult = result.data.filter((r) => r.userId);
      dispatch(
        paginationCreators.setValue(
          (papRequestPagination.total = filteredResult.length),
        ),
      );
      const project = await fetchPapProject(params.id);
      setPapProject(project);

      setPapRequests(filteredResult);
      setLoading(false);
    } catch (e) {
      if (e.data?.status === NO_MATCHING_DATA_FOUND || !params.id) {
        alert('존재하지 않는 PAP입니다.');
        history.push(`/${PAGE_URL.PAP}`);
      }
    }
  }, [dispatch, history, papRequestPagination, search]);

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  /** 선택한 요청 초기화 */
  const handleReset = () => {
    if (selectedRowKeys.length === 0) {
      message.warning('삭제할 항목을 선택하세요.');
      return;
    }

    Modal.confirm({
      title: '선택한 답변 초기화',
      content: `선택한 ${selectedRowKeys.length}개의 답변을 초기화하시겠습니까?`,
      okText: '확인',
      cancelText: '취소',
      onOk: async () => {
        try {
          const params = parse(search, { ignoreQueryPrefix: true });
          if (!params.id) throw new Error('invalid project id');

          // DELETE API 호출
          await deletePapProjectUserRequest(params.id, {
            requestIds: selectedRowKeys,
          });

          // 삭제 성공 메시지
          message.success('선택한 답변이 초기화되었습니다.');

          // 데이터 다시 불러오기
          fetchList();

          // 선택된 항목 초기화
          setSelectedRowKeys([]);
        } catch (error) {
          console.error('error', error);
          message.error('선택한 답변 초기화 중 오류가 발생했습니다.');
        }
      },
    });
  };

  const completeSelected = async () => {
    if (selectedRowKeys.length === 0) {
      notification.warning({
        message: '선택된 유저가 없습니다.',
        key: 'completeSelectedFail',
      });
      return;
    }
    const result = await Promise.allSettled(
      selectedRowKeys.map((requestId) =>
        updatePapUserRequest(
          requestId,
          {
            status: 'refund_complete',
            adminMemo: '지원금이 입금되었습니다. 등록된 계좌를 확인해주세요.',
          },
          true,
        ),
      ),
    );
    if (result.some((res) => res.status === 'rejected')) {
      const count = result.filter((res) => res.status === 'rejected').length;
      notification.error({
        message: `유저 ${result.length}명 중 ${count}명의 지원금 입금 완료 처리에 실패했습니다.`,
        key: 'completeSelectedFail',
      });
    } else {
      notification.success({
        message: '선택한 유저의 지원금 입금 완료 처리가 완료되었습니다.',
        key: 'completeSelectedSuccess',
      });
    }
    fetchList();
  };

  const handleMoveDetailPage = (record) => {
    history.push(
      `/${
        record.isTempUser
          ? PAGE_URL.PAP_POSTAL_USER_AUDIT_DETAIL
          : PAGE_URL.PAP_AUDIT_DETAIL
      }`,
      {
        userId: record?.userId ?? '',
        requestId: record?.id,
        type: 'pap',
      },
    );
  };

  const handleCreateDetailWindow = () => {
    if (findWindowById(papProject?.id)) {
      alert('이미 편집중인 프로젝트입니다.');
      return;
    }
    createWindow({
      id: `${NEW}${Date.now()}`,
      papId: papProject?.id,
      dataType: 'papPostalUser',
    });
  };

  const onDownloadUserDocument = async (id) => {
    const row = papRequests.find((r) => r.id === id);
    setDownloadingIds((prev) => [...prev, id]);
    const result = await downloadPapDocuments(id);
    const fileURL = window.URL.createObjectURL(
      new Blob([result], { type: 'application/zip' }),
    );

    const tempLink = document.createElement('a');
    tempLink.href = fileURL;
    tempLink.setAttribute(
      'download',
      `${papProject?.title}_${row.name}_${row.version}차.zip`,
    );
    tempLink.click();
    setTimeout(() => {
      setDownloadingIds((prev) => prev.filter((i) => i !== id));
    }, 3000);
    fetchList();
  };

  const exportFinancialReport = async () => {
    const params = parse(search, {
      ignoreQueryPrefix: true,
    });

    const result = await downloadPapProjectFinanceExcel(params.id);
    const csvURL = window.URL.createObjectURL(
      new Blob([`\ufeff${result}`], { type: 'text/csv' }),
    );
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute(
      'download',
      `${papProject.title}_대량_이체_내역_${moment().format('YY_MM_DD')}.csv`,
    );
    tempLink.click();
  };

  const exportPatientsReport = async () => {
    const params = parse(search, {
      ignoreQueryPrefix: true,
    });

    const result = await downloadPapProjectReportExcel(params.id);
    const csvURL = window.URL.createObjectURL(
      new Blob([`\ufeff${result}`], { type: 'text/csv' }),
    );
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute(
      'download',
      `${papProject.title}_제약사_보고용_${moment().format('YY_MM_DD')}.csv`,
    );
    tempLink.click();
  };

  const onTableChange = (pagination, filters, sorter, extra) => {
    dispatch(
      paginationCreators.setValue(
        (papRequestPagination.filter = filters),
        (papRequestPagination.sorter = sorter),
        (papRequestPagination.page = pagination.size),
        (papRequestPagination.total = extra?.currentDataSource?.length),
      ),
    );
  };

  const getColumnSearchDateProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
      return (
        <div
          style={{
            padding: 8,
          }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <DatePicker.RangePicker
            defaultValue={[
              selectedKeys?.[0]
                ? moment(selectedKeys?.[0], 'YYYY-MM-DD')
                : null,
              selectedKeys?.[1]
                ? moment(selectedKeys?.[1], 'YYYY-MM-DD')
                : null,
            ]}
            onChange={(e) => {
              if (e !== null) {
                if (
                  e?.[0].format('YYYY-MM-DD') === e?.[1].format('YYYY-MM-DD')
                ) {
                  setSelectedKeys([
                    e?.[0].format('YYYY-MM-DD'),
                    moment(e?.[1]).add(1, 'days').format('YYYY-MM-DD'),
                  ]);
                } else {
                  setSelectedKeys([
                    e?.[0].format('YYYY-MM-DD'),
                    e?.[1].format('YYYY-MM-DD'),
                  ]);
                }
              } else {
                setSelectedKeys([]);
              }
            }}
            format="YYYY-MM-DD"
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{
                width: 90,
              }}
            >
              Search
            </Button>
          </Space>
        </div>
      );
    },
    onFilter: (_, record) => {
      if (
        !papRequestPagination.filter[dataIndex] ||
        papRequestPagination.filter[dataIndex].length !== 2
      )
        return true;
      const [startStr, endStr] = papRequestPagination.filter[dataIndex];
      const value = moment(record[dataIndex]);
      const start = moment(startStr);
      const end = moment(endStr).add(1, 'days');
      return value.isBetween(start, end);
    },
  });

  const columns = [
    {
      title: '계정',
      dataIndex: 'email',
      key: 'email',
      width: 200,
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => {
            handleMoveDetailPage(record);
          }}
          style={{
            width: '100%',
            textAlign: 'left',
            whiteSpace: 'normal',
            wordBreak: 'break-all',
          }}
        >
          {record.isTempUser ? '우편 접수' : text ?? record.socialUid}
        </Button>
      ),
      sorter: (a, b) =>
        (a.email || a.socialUid)?.localeCompare(b.email || b.socialUid),
      filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => {
        confirmRef.current = (searchWord) => {
          if (searchWord) setSelectedKeys([searchWord]);
          else clearFilters();
          confirm();
        };
        return <></>;
      },
      filterIcon: () => <></>,
      onFilter: (keyword, { email, name }) =>
        [email, name].some((value) => value?.includes(keyword)),
    },
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text) => <Typography.Text ellipsis>{text}</Typography.Text>,
    },
    {
      title: '생년월일',
      dataIndex: 'birthDate',
      key: 'birthDate',
      render: (value) =>
        value ? <div>{moment(value).format('YYYY.MM.DD')}</div> : '-',
    },
    {
      title: '환자 번호',
      dataIndex: 'patientNumber',
      key: 'patientNumber',
      sorter: (a, b) => a.patientNumber.localeCompare(b.patientNumber),
    },
    {
      title: '지원 차수',
      key: 'version',
      dataIndex: 'version',
      render: (text, record) => (
        <div
          className="tagWrapper"
          key={record.id}
          style={{
            color: '#333',
            backgroundColor: '#fff',
          }}
        >
          {text}차
        </div>
      ),
      sorter: (a, b) => a.version - b.version,
      filters: papRequests.map((r) => ({
        text: `${r.version}차`,
        value: r.version,
      })),
      onFilter: (value, record) => record.version === value,
    },
    {
      title: '신청일시',
      key: 'requestDate',
      dataIndex: 'requestDate',
      render: (value) =>
        value ? <div>{moment(value).format('YYYY.MM.DD HH:mm')}</div> : '-',
      sorter: (a, b) => moment(a.requestDate || 0).diff(b.requestDate || 0),
      ...getColumnSearchDateProps('requestDate'),
    },
    {
      title: '유저 상태',
      key: 'status',
      dataIndex: 'status',
      render: (text, record) => (
        <div
          className="tagWrapper"
          key={record.id}
          style={{
            color: '#333',
            backgroundColor: '#fff',
          }}
        >
          {PAP_REQUEST_STATUS[text]}
        </div>
      ),
      sorter: (a, b) =>
        Object.keys(PAP_REQUEST_STATUS).indexOf(a.status) -
        Object.keys(PAP_REQUEST_STATUS).indexOf(b.status),
      filters: Object.keys(PAP_REQUEST_STATUS).map((key) => ({
        text: PAP_REQUEST_STATUS[key],
        value: key,
      })),
      onFilter: (value, record) => record.status === value,
    },
    {
      title: '처방 일자',
      key: 'prescriptionDate',
      dataIndex: 'prescriptionDate',
      render: (value) =>
        value ? <div>{moment(value).format('YYYY.MM.DD')}</div> : '-',
      sorter: (a, b) =>
        moment(a.prescriptionDate || 0).diff(b.prescriptionDate || 0),
      ...getColumnSearchDateProps('prescriptionDate'),
    },
    {
      title: '지원금',
      key: 'supportAmount',
      dataIndex: 'supportAmount',
      render: (value) => <div>{(value ?? '-')?.toLocaleString('ko-KR')}</div>,
      sorter: (a, b) => a.supportAmount - b.supportAmount,
    },
    {
      title: '사진 일괄 다운로드',
      key: 'download',
      render: (text, record) => {
        return (
          <Button
            onClick={() => {
              onDownloadUserDocument(record.id);
            }}
            disabled={record.documents.length === 0}
            loading={downloadingIds.includes(record.id)}
          >
            <DownloadOutlined style={{ fontSize: 16 }} />
          </Button>
        );
      },
    },
    {
      title: '최근 서류 등록일시',
      key: 'recentDocumentUpdatedAt',
      dataIndex: 'recentDocumentUpdatedAt',
      render: (value, record) => {
        if (value) return <div>{moment(value).format('YYYY.MM.DD HH:mm')}</div>;
        if (record.documents.length > 0) {
          const max = moment.max(
            record.documents.map((doc) => moment(doc.createdAt ?? 0)),
          );
          if (max.valueOf() > 0)
            return <div>{moment(max).format('YYYY.MM.DD HH:mm')}</div>;
        }
        return '-';
      },
      sorter: (a, b) =>
        moment(a.recentDocumentUpdatedAt || 0).diff(
          b.recentDocumentUpdatedAt || 0,
        ),
    },
  ];

  const { Search } = Input;
  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabData" link={PAGE_URL.PAP} />
        <Layout className="right-layout">
          <TitleBreadcrumb
            title="운영"
            subTitle="PAP 심사 관리"
            className="white-bg"
          />
          <Content className="site-layout-background contentStyle">
            <Typography.Title level={4}>{papProject?.title}</Typography.Title>
            <Button
              type="link"
              href={`/${PAGE_URL.PAP}`}
              onClick={(e) => {
                e.preventDefault();
                history.push(`/${PAGE_URL.PAP}`);
              }}
            >
              PAP 리스트로 돌아가기
            </Button>
            <Button
              type="primary"
              danger
              onClick={() => {
                handleReset();
              }}
            >
              선택한 답변 초기화
            </Button>
            <Divider />
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu>
                  <Menu.Item key="1" onClick={completeSelected}>
                    지원금 입금 완료로 변경 및 푸시 발송
                  </Menu.Item>
                </Menu>
              }
              disabled={selectedRowKeys.length === 0}
            >
              <Button>
                선택한 유저 <DownOutlined />
              </Button>
            </Dropdown>
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu>
                  <Menu.Item key="1" onClick={exportFinancialReport}>
                    대량 이체 엑셀 추출
                  </Menu.Item>
                  <Menu.Item key="2" onClick={exportPatientsReport}>
                    제약사 보고서 엑셀 추출
                  </Menu.Item>
                </Menu>
              }
            >
              <Button style={{ marginLeft: '8px' }}>
                전체 유저 <DownOutlined />
              </Button>
            </Dropdown>
            <Button
              type="primary"
              style={{ marginLeft: '8px' }}
              onClick={handleCreateDetailWindow}
            >
              우편 접수 환자 추가
            </Button>
            <Search
              placeholder="검색어를 입력해주세요."
              allowClear
              className="searchStyle"
              onSearch={(value) => {
                confirmRef.current(value);
                dispatch(
                  paginationCreators.setValue(
                    (papRequestPagination.filter.keyword =
                      value.length > 0 ? value : null),
                  ),
                );
              }}
              defaultValue={papRequestPagination.filter.keyword}
            />
            <div className="searchResult">{`검색결과 ${papRequestPagination.total}개`}</div>
            <Table
              loading={loading}
              pageUpdate={page}
              setPageUpdate={setPage}
              columns={columns}
              dataSource={papRequests}
              size="small"
              bordered
              rowSelection={{
                type: 'checkbox',
                selectedRowKeys,
                onChange: (selectedRowKeys) => {
                  setSelectedRowKeys(selectedRowKeys);
                },
              }}
              onChange={onTableChange}
              rowKey={(record) => record.id}
              pagination={{
                pageSize: 20,
                showSizeChanger: true,
              }}
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default PapAuditList;
