const PAGE_URL = {
  LOGIN: 'login',
  MAIN: 'main',
  ADMIN: 'admin',
  ADMIN_WINDOW: 'adminDetail',
  ADMIN_SETTING: 'adminSetting',
  USER: 'user',
  USER_WINDOW: 'userDetail',
  USER_WITHDRAWAL: 'userWithdrawal',
  MUTATION_DNA_REPORT: 'mutationDnaReport',
  MUTATION_DNA_REPORT_WINDOW: 'mutationDnaReportDetail',
  MUTATION_DNA_REPORT_REGISTER_REQUEST: 'mutationDnaReportRegisterRequest',
  HOSPITALEXAMINATION_RECORDS_REQUEST: 'hospitalExaminationRecordsRequest',
  DISEASETYPECERTIFICATION_REQUEST: 'diseaseTypeCertificationsRequest',
  MUTATION_DNA_REPORT_REGISTER_REQUEST_DETAIL:
    'mutationDnaReportRegisterRequestDetail',
  MUTATION_DNA: 'mutationDna',
  MUTATION_DNA_WINDOW: 'mutationDnaDetail',
  DNA: 'dna',
  DNA_WINDOW: 'dnaDetail',
  DICTIONARY: 'dictionary',
  DICTIONARY_WINDOW: 'dictionaryDetail',
  DISEASE_TYPE: 'diseaseType',
  DISEASE_TYPE_WINDOW: 'diseaseTypeDetail',
  DISEASE_TYPE_CLINICAL_TRIAL: 'diseaseTypeClinicalTrial',
  CLINICAL_TRIAL: 'clinicalTrial',
  CLINICAL_TRIAL_WINDOW: 'clinicalTrialDetail',
  HEALTH_PROFILE: 'healthProfile',
  HEALTH_PROFILE_USERS: 'healthProfileUsers',
  HEALTH_PROFILE_QUESTION: 'healthProfileQuestion',
  HEALTH_PROFILE_WINDOW: 'healthProfileDetail',
  HEALTH_PROFILE_QUESTION_WINDOW: 'healthProfileQuestionDetail',
  HEALTH_PROFILE_GROUP: 'healthProfileGroup',
  HEALTH_PROFILE_GROUP_WINDOW: 'healthProfileGroupDetail',
  HEALTH_PROFILE_GROUP_USERS: 'healthProfileGroupUsers',
  ENTRY_MODAL: 'entryModal',
  ENTRY_MODAL_WINDOW: 'entryModalDetail',
  NOTICE: 'notice',
  NOTICE_WINDOW: 'noticeDetail',
  NOTIFICATION: 'notification',
  INDIVIDUAL_NOTIFICATION: 'individualNotification',
  DASHBOARD: 'dashboard',
  DASHBOARD_DATA: 'dashboardData',
  HISTORY: 'history',
  HOSPITAL_RECORD: 'hospitalRecord',
  HOSPITAL_EXAMINATION_WINDOW: 'hospitalExaminationDetail',
  USER_HOSPITAL_EXAMINATION_WINDOW: 'userHospitalExaminationDetail',
  USER_DISEASE_TYPE_CERTIFICATION_WINDOW: 'userdiseaseTypeCertificationDetail',
  SUPPORT_VERSION: 'supportVersion',
  SUPPORT_VERSION_WINDOW: 'supportVersionDetail',
  RESEARCH: 'research',
  RESEARCH_WINDOW: 'researchDetail',
  GENE_RESEARCH: 'geneResearch',
  GENE_RESEARCH_DETAIL: 'geneResearchDetail',
  IPALLOW: 'ipAllow',
  IPALLOW_WINDOW: 'ipAllowDetail',
  CONTENTS: 'contents',
  CONTENTS_WINDOW: 'contentsDetail',
  DISEASEINFO_WINDOW: 'DiseaseInfoDetail',
  DOCTORPROFILE: 'doctorProfile',
  DOCTORPROFILE_WINDOW: 'doctorProfileDetail',
  PATIENT_GROUP: 'patientGroup',
  PATIENT_GROUP_USER: 'patientGroupUser',
  PATIENT_GROUP_WINDOW: 'patientGroupDetail',
  REMOTE_CONFIG: 'remoteConfig',
  REMOTE_CONFIG_WINDOW: 'remoteConfigDetail',
  RSS_FEED: 'rssFeed',
  RSS_FEED_WINDOW: 'rssFeedDetail',
  TOPIC_TAG: 'tag',
  TOPIC_TAG_WINDOW: 'tagDetail',
  TRANSLATE_WORD: 'translateWord',
  TRANSLATE_WORD_WINDOW: 'translateWordDetail',
  DISEASEINFO: 'diseaseInfo',
  PAP: 'papProject',
  PAP_PROJECT_WINDOW: 'papProjectDetail',
  PAP_TERM_WINDOW: 'papTermDetail',
  PAP_POSTAL_USER_WINDOW: 'papPostalUserDetail',
  PAP_POSTAL_USER_DOCUMENT_WINDOW: 'papPostalUserDocumentDetail',
  PAP_AUDIT: 'papAudit',
  PAP_AUDIT_DETAIL: 'papAuditDetail',
  PAP_POSTAL_USER_AUDIT_DETAIL: 'papPostalUserAuditDetail',
  PSP: 'pspProject',
  PSP_PROJECT_WINDOW: 'pspProjectDetail',
  PSP_TERM_WINDOW: 'pspTermDetail',
  PSP_AUDIT: 'pspAudit',
  PSP_AUDIT_DETAIL: 'pspAuditDetail',
  PSP_CONTENTS_WINDOW: 'pspContentsDetail',
  MEDIA_MONITOR_CONFIG: 'mediaMonitorConfig',
  MEDIA_MONITOR_CONFIG_WINDOW: 'mediaMonitorConfigDetail',
  MEDIA_MONITOR_PREVIEWS_WINDOW: 'mediaMonitorPreviewsDetail',
  MEDIA_MONITOR_CONFIG_SETTING: 'mediaMonitorConfigSetting',
  MEDIA_MONITOR_NEWS: 'mediaMonitorNews',
  MEDIA_MONITOR_NEWS_WINDOW: 'mediaMonitorNewsDetail',
  MEDIA_MONITOR_LETTER_WINDOW: 'mediaMonitorLetterDetail',
  RAISING_AWARENESS: 'raisingAwareness',
  RAISING_AWARENESS_WINDOW: 'raisingAwarenessDetail',
  RAISING_AWARENESS_USERS: 'raisingAwarenessUsers',
  RAISING_AWARENESS_PAGE: 'raisingAwarenessPage',
  RAISING_AWARENESS_PAGE_WINDOW: 'raisingAwarenessPageDetail',
  RAISING_AWARENESS_REGISTER_REQUEST: 'raisingAwarenessRegisterRequest',
};

export default PAGE_URL;
