/**
 *
 * @param {'pap' | 'psp' | 'healthProfileGroupSubject'} type
 * @param {String} id // pap project id, psp project id, healthprofilegroupsubject id
 */
export function getShareLinkUrl(type, id) {
  const url =
    process.env.REACT_APP_DEPLOY === 'production'
      ? `https://api.humanscape.io/v1/shareLink/${type}/${id}`
      : process.env.REACT_APP_DEPLOY === 'staging'
      ? `https://api-stage.humanscape.io/v1/shareLink/${type}/${id}`
      : `https://api-dev.humanscape.io/v1/shareLink/${type}/${id}`;
  return url;
}
