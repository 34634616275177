import { FileTwoTone, WalletTwoTone } from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Layout,
  Row,
  Skeleton,
  Tabs,
  Typography,
} from 'antd';
import { parse } from 'qs';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PAGE_URL from '../../../assets/pageUrl';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import { useFetch } from '../../../hooks/useRequest';
import { fetchPapPostalUser } from '../../../services/papService';
import * as listMap from '../../../util/listMap';
import { getAgeFromBirthDate } from '../../../util/time';
import UserPapRequest from '../user/userPapRequest';
import useWindow from '../../../hooks/useWindow';
import {
  HEALTH_PROFILE_ACCOUNT_TYPES,
  HEALTH_PROFILE_GENDER,
} from '../../../util/healthProfileConstants';

const PapPostalUserAuditDetail = () => {
  const { Content } = Layout;
  const { state, search } = useLocation();
  const history = useHistory();

  const { TabPane } = Tabs;
  const { Title } = Typography;
  const [form] = Form.useForm();
  const userDataFetchDone = useRef(false);
  const { findWindowById, createWindow, destroyWindowById } = useWindow();

  const params = parse(search, {
    ignoreQueryPrefix: true,
  });
  const userId = state?.userId ?? params.userId;

  if (!userId) {
    alert('잘못된 접근입니다.');
    history.goBack();
  }
  const { done: fetchUserDone, data: userData, call: fetchUser } = useFetch(
    null,
    fetchPapPostalUser,
    userId,
  );
  const loading = !userData;

  useEffect(() => {
    if (!fetchUserDone && userId && !userDataFetchDone.current) {
      fetchUser();
      userDataFetchDone.current = true;
    }
  }, [fetchUser, fetchUserDone, userId]);

  useEffect(() => {
    if (fetchUserDone) {
      form.setFieldsValue({
        name: userData?.name,
        birthday: userData?.birthday,
        phone: userData?.phone,
        age: `만 ${userData?.age}세`,
        gender: HEALTH_PROFILE_GENDER[userData?.gender],
        isUnder14: listMap.agreeMap[userData?.isUnder14],
        diseaseType: userData?.diseaseType,
        accountType: HEALTH_PROFILE_ACCOUNT_TYPES[userData?.accountType],
      });
    }
  }, [fetchUserDone]);

  const BasicData = useCallback(({ label, type, value }) => {
    return (
      <>
        <Form.Item label={label} name={type} initialValue={value} value={value}>
          <Input disabled />
        </Form.Item>
      </>
    );
  }, []);

  const editUser = () => {
    if (findWindowById(userId)) {
      alert('이미 편집중입니다.');
      return;
    }
    createWindow({
      id: userId,
      dataType: 'papPostalUser',
    });
  };
  const [isPapPermitted, setIsPapPermitted] = useState(true);

  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tabLink={PAGE_URL.PAP} link="papProject" />
        <Layout className="right-layout-floud">
          <TitleBreadcrumb
            title="계정"
            subTitle="PAP 심사"
            className="white-bg"
          />
          <Content className="site-layout-background userContentStyle">
            <Row span={24}>
              <Col span={12} style={{ paddingRight: 8 }}>
                <Content>
                  <Button type="primary" onClick={() => history.goBack()}>
                    목록 돌아가기
                  </Button>
                </Content>
                <Content style={{ marginTop: 16 }}>
                  <Row span={24} className="user_userInfo">
                    <Col span={24}>
                      <h5>
                        <FileTwoTone className="userTitleIcon" />
                        개인 정보
                      </h5>
                      <Divider plain />
                      <Button
                        type="primary"
                        className="userInfoBtn"
                        onClick={editUser}
                        disabled={loading}
                      >
                        수정하기
                      </Button>
                      <Tabs
                        defaultActiveKey="default"
                        className="userDetailTab"
                      >
                        <TabPane tab="개인 정보" key="default">
                          {loading ? (
                            <Skeleton active />
                          ) : (
                            <Form name="basic" form={form} {...listMap.layout}>
                              <Row span={24} style={{ marginBottom: 12 }}>
                                <Col span={12}>
                                  <BasicData
                                    label="계정 유형"
                                    type="isTemp"
                                    value="우편 접수"
                                  />
                                  <BasicData
                                    label="계정"
                                    type="email"
                                    value="우편 접수"
                                  />
                                </Col>
                              </Row>

                              <Row span={24}>
                                <Col span={12}>
                                  <Row>
                                    <Col span={2} />
                                    <Title level={5}>기타 정보</Title>
                                  </Row>
                                  <BasicData
                                    label="이름"
                                    type="name"
                                    value={userData?.name}
                                  />
                                  <BasicData
                                    label="연락처"
                                    type="phone"
                                    value={userData?.phone}
                                  />
                                  <BasicData
                                    label="생년월일"
                                    type="birthday"
                                    value={userData?.birthday}
                                  />
                                  <BasicData label="만 나이" type="age" />
                                  <BasicData label="성별" type="gender" />
                                </Col>
                                <Col span={12}>
                                  <Row>
                                    <Col span={2} />
                                    <Title level={5}>기타 정보</Title>
                                  </Row>
                                  <BasicData
                                    label="질환"
                                    type="diseaseType.name"
                                    value={userData?.diseaseType?.name}
                                  />
                                  <BasicData label="관계" type="accountType" />
                                  <BasicData
                                    label="14세 미만"
                                    type="isUnder14"
                                  />
                                </Col>
                              </Row>
                            </Form>
                          )}
                        </TabPane>
                      </Tabs>
                    </Col>
                  </Row>
                </Content>
              </Col>
              <Col span={12} style={{ paddingLeft: 8 }}>
                <Content>
                  <Row span={24}>
                    <Col span={24}>
                      <h5>
                        <WalletTwoTone className="userTitleIcon" />
                        데이터 보관함
                      </h5>
                      <Divider plain />
                      <Tabs
                        defaultActiveKey="default"
                        className="userDetailTab"
                      >
                        {isPapPermitted && (
                          <TabPane tab="PAP" key="pap">
                            <UserPapRequest
                              userId={userId}
                              setIsPapPermitted={setIsPapPermitted}
                              isTempUser={true}
                            />
                          </TabPane>
                        )}
                      </Tabs>
                    </Col>
                  </Row>
                </Content>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default PapPostalUserAuditDetail;
