import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Input, Layout, notification, Switch, Tabs } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useHistory, useLocation } from 'react-router-dom';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import AntList from '../../components/antList';
import {
  fetchAllPapProjects,
  updatePapProject,
} from '../../../services/papService';
import PAGE_URL from '../../../assets/pageUrl';
import { paginationCreators } from '../../../store/reducers/pagination.reducer';
import useWindow from '../../../hooks/useWindow';
import { NEW } from '../../../window/util/utils';
import { PAP_STATUS } from '../../../util/papConstants';
import { DownloadOutlined } from '@ant-design/icons';
import { QRCodeSVG } from 'qrcode.react';
import { getShareLinkUrl } from '../../../services/shareLinkService';
import PapPostalList from './papPostalList';
import { parse } from 'qs';

const PapProjectList = () => {
  const [papProjects, setPapProjects] = useState([]);
  const [deployChangeInfo, setDeployChangeInfo] = useState([]);
  const [pageUpdate, setPageUpdate] = useState(1);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { findWindowById, createWindow, destroyWindowById } = useWindow();
  const { papProjectPagination } = useSelector((state) => {
    return {
      diseaseTypeData: state.diseaseTypeReducer.diseaseTypes.data,
      papProjectPagination: state.paginationReducer.papProjectPagination,
    };
  });
  const confirmRef = useRef(() => {});
  const [key, setKey] = useState('default');
  const { TabPane } = Tabs;
  const { search } = useLocation();

  useEffect(() => {
    const params = parse(search, {
      ignoreQueryPrefix: true,
    });
    setKey(params.tab ?? 'default');
  }, [search, setKey]);

  const fetchList = useCallback(async () => {
    setLoading(true);
    const result = await fetchAllPapProjects();
    dispatch(
      paginationCreators.setValue((papProjectPagination.total = result.count)),
    );
    setPapProjects(result.data);
    setLoading(false);
  }, [dispatch, papProjectPagination]);

  useEffect(() => {
    if (deployChangeInfo.length > 0) {
      Promise.all(
        deployChangeInfo.map((change) => {
          return updatePapProject(change.id, { isApp: change.isApp });
        }),
      ).then((result) => {
        notification.success({
          key: 'deployChange',
          message: '앱 노출 상태를 성공적으로 변경하였습니다.',
        });
        setDeployChangeInfo([]);
        const ids = result.map((r) => r.id);
        setPapProjects(
          papProjects.map((project) => {
            if (ids.includes(project.id)) {
              return {
                ...project,
                isApp: result.find((r) => r.id === project.id).isApp,
              };
            }
            return project;
          }),
        );
      });
    }
  }, [deployChangeInfo]);

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  const TagWrapper = ({ tag }) => {
    return (
      <div
        className="tagWrapper"
        key={tag}
        style={{
          color: '#333',
        }}
      >
        {tag}
      </div>
    );
  };

  const handleCreateDetailWindow = (papProject) => {
    if (findWindowById(papProject?.id)) {
      alert('이미 편집중인 프로젝트입니다.');
      return;
    }
    createWindow({
      id: papProject?.id ?? `${NEW}${Date.now()}`,
      dataType: 'papProject',
    });
  };

  const receiveMessage = useCallback(
    (event) => {
      if (
        event.origin !== window.location.origin ||
        typeof event.data !== 'string'
      )
        return;
      const [command, id] = event.data.split(' ');
      if (command === 'close' && id.length > 0) {
        destroyWindowById(id);
        fetchList();
      }
    },
    [fetchList, destroyWindowById],
  );

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, [receiveMessage]);

  const onChange = (pagination, filters, sorter, extra) => {
    dispatch(
      paginationCreators.setValue(
        (papProjectPagination.page = pagination?.current),
        (papProjectPagination.filter = filters),
        (papProjectPagination.sorter = sorter),
        (papProjectPagination.total = extra?.currentDataSource?.length),
      ),
    );
  };

  const updateTabQuery = (key, val) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(key, val);
    history.replace({
      pathname: location.pathname,
      search: searchParams.toString() ? `?${searchParams.toString()}` : '',
    });
  };

  const columns = [
    {
      title: '등록일',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '120px',
      render: (text) => dayjs(text).format('YY-MM-DD'),
      sorter: {
        compare: (a, b) => a?.createdAt?.localeCompare(b?.createdAt),
      },
    },
    {
      title: 'PAP명',
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => (
        <Button
          type="link"
          style={{
            width: '100%',
            textAlign: 'left',
            whiteSpace: 'normal',
          }}
          href={`/${PAGE_URL.PAP_AUDIT}?id=${record.id}`}
          onClick={(e) => {
            e.preventDefault();
            history.push(`/${PAGE_URL.PAP_AUDIT}?id=${record.id}`);
          }}
        >
          {text}
        </Button>
      ),
      sorter: {
        compare: (a, b) => a?.title?.localeCompare(b?.title),
      },
      filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => {
        confirmRef.current = (searchWord) => {
          if (searchWord) setSelectedKeys([searchWord]);
          else clearFilters();
          confirm();
        };
        return <></>;
      },
      filterIcon: () => <></>,
      onFilter: (search, { title, createdAt, diseaseTypeNames }) =>
        [title, createdAt, diseaseTypeNames].some((value) =>
          value?.includes(search),
        ),
    },
    {
      title: 'PAP 내용 수정',
      width: '110px',
      render: (_, record) => (
        <Button onClick={() => handleCreateDetailWindow(record)}>
          수정하기 &gt;
        </Button>
      ),
    },
    {
      title: '질환명',
      dataIndex: 'diseaseTypes',
      key: 'diseaseTypes',
      width: 300,
      render: (diseaseArr) => {
        return (
          <>
            {diseaseArr?.map((disease) => (
              <TagWrapper key={disease.id} tag={disease.krName} />
            ))}
          </>
        );
      },
      sorter: {
        compare: (a, b) =>
          a?.diseaseTypeNames?.localeCompare(b?.diseaseTypeNames),
      },
    },
    {
      title: '신청자 수',
      dataIndex: 'requestUserCount',
      key: 'requestUserCount',
      width: '90px',
      sorter: (a, b) => a.requestUserCount - b.requestUserCount,
    },
    {
      title: '앱 노출',
      dataIndex: 'isApp',
      key: 'isApp',
      width: '80px',
      render: (text, record) => (
        <div>
          <Switch checked={record?.isApp} size="small" />
        </div>
      ),
      filters: [
        {
          text: 'On',
          value: true,
        },
        {
          text: 'Off',
          value: false,
        },
      ],
      onFilter: (value, record) => record.isApp === value,
    },
    {
      title: '신청 상태',
      dataIndex: 'status',
      key: 'status',
      width: '110px',
      render: (text) => PAP_STATUS[text],
      sorter: true,
      filters: Object.keys(PAP_STATUS).map((key) => ({
        text: PAP_STATUS[key],
        value: key,
      })),
      onFilter: (value, record) => record.status === value,
    },
    {
      title: 'QR 다운로드',
      dataIndex: 'qrLink',
      key: 'qrLink',
      width: '100px',
      render: (text, record) => {
        const shareLink = getShareLinkUrl('pap', record.id);
        return (
          <div>
            <Button
              icon={<DownloadOutlined />}
              onClick={(e) => {
                const svgElement = e.currentTarget.nextSibling;
                if (!shareLink || !svgElement) {
                  notification.error({
                    message: 'QR 코드가 생성되지 않았습니다.',
                    key: 'qrCodeNotGenerated',
                  });
                  return;
                }
                const serialized = new XMLSerializer().serializeToString(
                  svgElement,
                );
                const blob = new Blob([serialized], {
                  type: 'image/svg+xml;charset=utf-8',
                });
                const a = document.createElement('a');
                a.href = URL.createObjectURL(blob);
                a.download = `${record?.title}.svg`;
                a.click();
              }}
            />
            <QRCodeSVG value={shareLink} hidden />
          </div>
        );
      },
    },
  ];

  const { Search } = Input;
  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabData" link={PAGE_URL.PAP} />
        <Layout className="right-layout">
          <TitleBreadcrumb title="운영" subTitle="PAP" className="white-bg" />
          <Content className="site-layout-background contentStyle">
            <Tabs
              activeKey={key}
              onChange={(tab) => {
                setKey(tab);
                updateTabQuery('tab', tab);
              }}
              className="userDetailTab"
            >
              <TabPane tab="PAP" key="default">
                <Button type="primary" onClick={handleCreateDetailWindow}>
                  신규 PAP 등록
                </Button>
                <Search
                  placeholder="검색어를 입력해주세요."
                  allowClear
                  className="searchStyle"
                  onSearch={(value) => {
                    confirmRef.current(value);
                    dispatch(
                      paginationCreators.setValue(
                        (papProjectPagination.filter.keyword =
                          value.length > 0 ? value : null),
                      ),
                    );
                  }}
                  defaultValue={papProjectPagination.filter.keyword}
                />
                <div className="searchResult">{`검색결과 ${papProjectPagination.total}개`}</div>
                <AntList
                  loading={loading}
                  deployChangeInfo={deployChangeInfo}
                  setDeployChangeInfo={setDeployChangeInfo}
                  pageUpdate={pageUpdate}
                  setPageUpdate={setPageUpdate}
                  columns={columns}
                  dataSource={papProjects}
                  onChange={onChange}
                  pagination={{
                    pageSize: 20,
                    showSizeChanger: true,
                  }}
                />
              </TabPane>
              <TabPane tab="우편 접수 환자" key="postal">
                <PapPostalList papProjects={papProjects} />
              </TabPane>
            </Tabs>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default PapProjectList;
