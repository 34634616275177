import {
  DownloadOutlined,
  FileImageTwoTone,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Empty,
  Form,
  Input,
  InputNumber,
  Menu,
  message,
  Modal,
  notification,
  Row,
  Select,
  Skeleton,
  Space,
  Table,
  Typography,
} from 'antd';
import moment from 'moment';
import { parse } from 'qs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  createPapPostalUserRequest,
  deletePapProjectUserRequest,
  downloadPapDocument,
  downloadPapDocuments,
  fetchPapPostalUser,
  fetchPapUserRequest,
  fetchPapUserRequestHistories,
  updatePapUserRequest,
} from '../../../services/papService';
import { fetchRemoteConfig } from '../../../services/remoteConfigService';
import { fetchUser } from '../../../services/userService';
import { FORBIDDEN, NO_MATCHING_DATA_FOUND } from '../../../services/utils';
import {
  PAP_REQUEST_STATUS,
  PAP_REQUEST_STATUS_EDITABLE,
} from '../../../util/papConstants';
import useWindow from '../../../hooks/useWindow';
import { NEW } from '../../../window/util/utils';

const STATUS_WITH_PUSH = [
  'document_missing',
  'account_missing',
  'eligible',
  'ineligible',
  'refund_complete',
];
const INITIAL_DOSE_INFO = {
  volume: '', // 용량
  unit: 'mg', // 용량 단위
  prescription_count: '', // 처방 갯수
  prescription_days: '', // 처방 일수
  unit_price: '', // 단가
  prescription_total_count: '', // 총 처방 갯수
};
const INITIAL_PRESCRIPTION = {
  prescription_date: '', // 처방 일자
  treatment_rounds: '', // 치료 차수
  received_amount: '', // 수납 금액
  received_date: '', // 수납 일자
  dose_info: [INITIAL_DOSE_INFO],
};

const INITIAL_VALUE_BY_KEY = {
  prescriptions: INITIAL_PRESCRIPTION,
  dose_info: INITIAL_DOSE_INFO,
};

const formatColum = (val) => {
  const col = {
    title: val.name,
    dataIndex: val.key,
    key: val.key,
  };
  switch (val.type) {
    case 'text':
      col.render = (text) =>
        val.key.includes('rounds') && text ? (
          <span style={{ color: 'red' }}>{text}차</span>
        ) : (
          text
        );
      break;
    case 'date':
      col.render = (text) => (text ? moment(text).format('YY.MM.DD') : '');
      break;
    case 'number':
      col.render = (text) =>
        val.key.includes('count')
          ? `${text}개`
          : val.key.includes('days')
          ? `${text}일`
          : text
          ? String(text)?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          : '';
      break;
    case 'unit':
      col.render = (text) => text;
      break;
  }
  return col;
};
const formatMomentValues = (data) => {
  if (moment.isMoment(data)) {
    return data.toISOString();
  }
  if (Array.isArray(data)) {
    return data.map((item) => formatMomentValues(item));
  }
  if (data !== null && typeof data === 'object') {
    const formattedObj = {};
    Object.keys(data).forEach((key) => {
      formattedObj[key] = formatMomentValues(data[key]);
    });
    return formattedObj;
  }
  return data;
};

const UserPapRequest = ({ userId, setIsPapPermitted, isTempUser }) => {
  const [papProjects, setPapProjects] = useState([]);
  const [papRequests, setPapRequests] = useState([]);
  const [papLoading, setPapLoading] = useState(true);
  const [formSaving, setFormSaving] = useState(false);
  const [selectedPapProjectId, setSelectedPapProjectId] = useState('');
  const [selectedPapRequestId, setSelectedPapRequestId] = useState('');
  const [currentPapRequest, setCurrentPapRequest] = useState(null);
  const [patientNumber, setPatientNumber] = useState('');
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [isDownloadingDocuments, setIsDownloadingDocuments] = useState(false);
  const [previousMemo, setPreviousMemo] = useState('');
  const { state, search } = useLocation();
  const history = useHistory();
  const params = parse(search, {
    ignoreQueryPrefix: true,
  });
  const { findWindowById, createWindow, destroyWindowById } = useWindow();

  const requestId = state?.requestId ?? params.reqId;

  const [documentSchema, setDocumentSchema] = useState([]);

  const [userStatusForm] = Form.useForm();
  const [hospitalForm] = Form.useForm();
  const [bancAccountForm] = Form.useForm();
  const [documentForm] = Form.useForm();

  const isNokAccount = currentPapRequest?.isRefundNOK;

  const fetchHistories = useCallback(async () => {
    if (selectedPapRequestId?.length === 0) return;
    const result = await fetchPapUserRequestHistories(selectedPapRequestId);
    setHistoryData(result);
  }, [selectedPapRequestId]);

  const fetchPapRequests = useCallback(async () => {
    try {
      const result = await fetchPapUserRequest(userId);
      setPapRequests(result.requests);
      setPapProjects(result.projects);

      const config = await fetchRemoteConfig('pap_preset_attributes');
      setDocumentSchema(JSON.parse(config.pap_preset_attributes.value));
      setPapLoading(false);
    } catch (e) {
      if (e.status === 200 && e.data.status === FORBIDDEN) {
        setIsPapPermitted(false);
      } else {
        if (e.status === 200 && e.data.status === NO_MATCHING_DATA_FOUND) {
          alert('유효하지 않은 요청입니다.');
          history.goBack();
          return;
        }
        throw e;
      }
    }
  }, [history, setIsPapPermitted, userId]);

  useEffect(() => {
    fetchHistories();
  }, [fetchHistories]);

  const onSave = async (form, isSendPush) => {
    setFormSaving(true);
    const result = await updatePapUserRequest(
      selectedPapRequestId,
      form,
      isSendPush,
    );
    await fetchHistories();
    notification.success({
      message: '변경사항이 저장되었습니다.',
      key: 'saveSuccess',
      placement: 'topLeft',
    });
    setCurrentPapRequest(result);
    setFormSaving(false);
  };

  useEffect(() => {
    if (currentPapRequest) {
      userStatusForm.resetFields();
      userStatusForm.setFieldsValue({
        adminMemo: currentPapRequest.adminMemo,
        status: currentPapRequest.status,
      });
      hospitalForm.resetFields();
      hospitalForm.setFieldsValue(currentPapRequest.doctorInfo);
      bancAccountForm.resetFields();
      bancAccountForm.setFieldsValue(currentPapRequest.refundInfo);
      documentForm.resetFields();
      documentForm.setFieldsValue(currentPapRequest.formData);
    }
  }, [
    bancAccountForm,
    currentPapRequest,
    documentForm,
    hospitalForm,
    userStatusForm,
  ]);

  useEffect(() => {
    const project = papProjects.find((p) => p.id === selectedPapProjectId);
    setPatientNumber(project?.patientNumber);
    if (!currentPapRequest || selectedPapProjectId === '') return;
    if (currentPapRequest?.projectId !== selectedPapProjectId) {
      setSelectedPapRequestId(
        papRequests?.find((r) => r.projectId === selectedPapProjectId)?.id ??
          '',
      );
    }
  }, [currentPapRequest, papProjects, papRequests, selectedPapProjectId]);

  useEffect(() => {
    fetchPapRequests();
  }, []);

  useEffect(() => {
    if (selectedPapRequestId?.length > 0) {
      const request = papRequests?.find((r) => r.id === selectedPapRequestId);
      setCurrentPapRequest(request);
    } else {
      let reqId = requestId;
      if (!reqId) {
        if (selectedPapProjectId.length > 0) {
          reqId = papRequests.find((r) => r.projectId === selectedPapProjectId)
            ?.id;
        } else {
          reqId = '';
        }
      }
      setSelectedPapRequestId(reqId);
    }
    const projectId =
      papRequests.find((r) => r.id === selectedPapRequestId)?.projectId ?? '';
    if (projectId.length > 0) setSelectedPapProjectId(projectId);
  }, [
    papRequests,
    papProjects,
    selectedPapRequestId,
    selectedPapProjectId,
    requestId,
  ]);

  useEffect(() => {
    if (selectedPapRequestId) {
      const request = papRequests.find((r) => r.id === selectedPapRequestId);
      setCurrentPapRequest(request);
    }
  }, [papRequests, selectedPapRequestId]);

  const onDownloadSingleDocument = useCallback(
    async (docId) => {
      const result = await downloadPapDocument(selectedPapRequestId, docId);
      const link = document.createElement('a');
      link.href = result;
      link.click();
      fetchPapRequests();
      fetchHistories();
    },
    [fetchHistories, fetchPapRequests, selectedPapRequestId],
  );

  /** 우편접수환자 목록에서 진입시 첫번째 프로젝트 선택 */
  useEffect(() => {
    const requestId = state?.requestId ?? params.reqId;
    if (isTempUser && papProjects.length > 0 && !requestId) {
      setSelectedPapProjectId((prev) => prev || papProjects[0].id);
    }
  }, [setSelectedPapProjectId, papProjects, state, params, isTempUser]);

  const onUploadDocument = () => {
    if (findWindowById(currentPapRequest?.id)) {
      alert('이미 편집중인 프로젝트입니다.');
      return;
    }
    createWindow({
      id: currentPapRequest?.id,
      projectId: selectedPapProjectId,
      userId,
      dataType: 'papPostalUserDocument',
    });
  };

  const onDownloadAllDocuments = useCallback(async () => {
    setIsDownloadingDocuments(true);
    try {
      const result = await downloadPapDocuments(selectedPapRequestId);
      const userInfo = isTempUser
        ? await fetchPapPostalUser(userId)
        : await fetchUser(userId);
      const currentProjectTitle = papProjects.find(
        (p) => p.id === selectedPapProjectId,
      )?.title;

      const fileURL = window.URL.createObjectURL(
        new Blob([result], { type: 'application/zip' }),
      );

      const tempLink = document.createElement('a');
      tempLink.href = fileURL;
      tempLink.setAttribute(
        'download',
        `${currentProjectTitle}_${userInfo.name}_${currentPapRequest?.version}차.zip`,
      );
      tempLink.click();
      fetchPapRequests();
      fetchHistories();
    } catch (e) {
      notification.error({
        message: '서류 다운로드에 실패했습니다.',
        key: 'downloadError',
      });
    }
    setTimeout(() => {
      setIsDownloadingDocuments(false);
    }, 3000);
  }, [
    currentPapRequest?.version,
    fetchHistories,
    fetchPapRequests,
    papProjects,
    selectedPapProjectId,
    selectedPapRequestId,
    userId,
  ]);

  const renderDocumentSchema = (col, key) => {
    const name = key ? key : col.key;
    if (col.type === 'text') {
      return (
        <Form.Item label={col.name} name={name} key={name}>
          <Input />
        </Form.Item>
      );
    }
    if (col.type === 'textarea') {
      return (
        <Form.Item label={col.name} name={name} key={name}>
          <Input.TextArea rows={4} />
        </Form.Item>
      );
    }
    if (col.type === 'number') {
      return (
        <Form.Item label={col.name} name={name} key={name}>
          <InputNumber
            style={{ width: '100%' }}
            formatter={(v) => v?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          />
        </Form.Item>
      );
    }
    if (col.type === 'date') {
      return (
        <Form.Item
          label={col.name}
          name={name}
          key={name}
          getValueProps={(v) => ({
            value: v ? moment(v) : null,
          })}
        >
          <DatePicker />
        </Form.Item>
      );
    }
    if (col.type === 'unit') {
      const unitName = [...name.slice(0, name.length - 1), 'unit'];
      return (
        <Form.Item key={name} label={col.name}>
          <Form.Item name={name} style={{ flex: 1 }}>
            <Input
              type="text"
              addonAfter={
                <Form.Item noStyle name={unitName}>
                  <Select>
                    {col.meta.choices.map((option) => (
                      <Select.Option value={option} key={option}>
                        {option}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              }
            />
          </Form.Item>
        </Form.Item>
      );
    }
    if (col.type === 'list') {
      return (
        <Form.List name={name} key={name}>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <Row key={field.key} gutter={16} style={{ marginBottom: 8 }}>
                  <Col
                    style={{
                      flexDirection: 'column',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Button
                      icon={<MinusCircleOutlined />}
                      onClick={() => remove(field.name)}
                      type="text"
                    />
                    <Divider
                      type="vertical"
                      style={{
                        flexGrow: 1,
                        borderColor: '#f0f0f0',
                        borderWidth: 2,
                      }}
                    />
                  </Col>
                  <Col flex={1}>
                    {col.fields?.map((subCol) => {
                      return renderDocumentSchema(subCol, [
                        field.name,
                        subCol.key,
                      ]);
                    })}
                  </Col>
                </Row>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => {
                    add(INITIAL_VALUE_BY_KEY[col.key]);
                  }}
                  block
                  icon={<PlusOutlined />}
                >
                  {col.name} 추가
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      );
    }
    return <></>;
  };

  const addTempUserRequest = async () => {
    try {
      const userResult = await createPapPostalUserRequest(userId, {
        projectId: selectedPapProjectId,
      });
      const result = await fetchPapUserRequest(userId);
      setPapRequests(result.requests);
      message.success(`차수가 추가되었습니다.`);
    } catch (e) {
      if (e.status === 200 && e.data.status === FORBIDDEN) {
        setIsPapPermitted(false);
      } else {
        message.error(`차수 추가 중 오류가 발생했습니다.`);
      }
    }
  };
  const deleteTempUserRequest = async () => {
    if (
      window.confirm(
        '해당 차수의 모든 정보가 삭제되고 해당 차수가 제거됩니다. 정말 제거하시겠습니까?',
      )
    ) {
      try {
        await deletePapProjectUserRequest(selectedPapProjectId, {
          requestIds: [selectedPapRequestId],
        });
        const result = await fetchPapUserRequest(userId);
        setPapRequests(result.requests);
        const nextCurrentRequest = result.requests[0];
        setCurrentPapRequest(nextCurrentRequest);
        setSelectedPapRequestId(nextCurrentRequest.id);
        message.success('차수가 삭제되었습니다.');
      } catch (e) {
        message.error('차수 삭제 중 오류가 발생했습니다.');
      }
    }
  };

  const overViewColumns = documentSchema
    .find((schema) => schema.key === 'prescriptions')
    ?.fields?.reduce((acc, val) => {
      if (val.type === 'list') {
        const formattedColumns = val.fields.map((subVal) =>
          formatColum(subVal),
        );
        return acc.concat([
          formattedColumns[0],
          formattedColumns[2],
          formattedColumns[1],
          formattedColumns[4],
          formattedColumns[3],
        ]);
      }
      return acc.concat(formatColum(val));
    }, []);

  const overViewData = useMemo(() => {
    const data = documentForm.getFieldsValue();
    if (!data?.prescriptions) return [];
    return data?.prescriptions?.reduce((acc, val) => {
      return acc.concat(
        val.dose_info.map((doseInfo, i) => {
          return {
            ...(i === 0 && val),
            ...doseInfo,
            volume: `${doseInfo.volume} ${doseInfo.unit}`,
          };
        }),
      );
    }, []);
  }, [documentForm.getFieldsValue()]);

  return (
    <Row wrap={false}>
      {papLoading ? (
        <Skeleton active />
      ) : (
        <>
          <Col style={{ maxWidth: 160 }}>
            <Menu
              mode="inline"
              items={papProjects.map((p) => ({
                label: p.title,
                key: p.id,
              }))}
              selectedKeys={selectedPapProjectId}
              onClick={(e) => setSelectedPapProjectId(e?.key)}
              style={{
                height: '100%',
              }}
            />
          </Col>
          <Col flex={1} style={{ padding: '8px 16px' }}>
            {currentPapRequest ? (
              <>
                <Form labelCol={{ span: 5 }}>
                  <Form.Item label="환자 번호">
                    <Input readOnly value={patientNumber} />
                  </Form.Item>
                  <Form.Item
                    label={
                      <>
                        신청/우편 접수
                        <br />
                        일시(차수)
                      </>
                    }
                  >
                    <Select
                      value={selectedPapRequestId}
                      onSelect={setSelectedPapRequestId}
                      style={{ width: 180 }}
                    >
                      {papRequests
                        ?.filter((r) => r.projectId === selectedPapProjectId)
                        .sort((a, b) => b.version - a.version)
                        .map((r) => (
                          <Select.Option value={r.id} key={r.id}>
                            {moment(r.requestDate || r.createdAt).format(
                              'YY.MM.DD HH:mm',
                            )}
                            ({r.version}차)
                          </Select.Option>
                        ))}
                    </Select>

                    {isTempUser && (
                      <div
                        style={{
                          display: 'inline-flex',
                          gap: 2,
                          marginLeft: 3,
                        }}
                      >
                        <Button
                          onClick={addTempUserRequest}
                          style={{
                            padding: '4px 5px',
                          }}
                        >
                          차수 추가
                        </Button>
                        {papRequests.find((r) => r.id === selectedPapRequestId)
                          ?.version > 1 ? (
                          <Button
                            onClick={deleteTempUserRequest}
                            style={{ padding: '4px 5px' }}
                          >
                            차수 제거
                          </Button>
                        ) : null}
                      </div>
                    )}
                  </Form.Item>
                  <Form.Item label="업로드 된 서류">
                    {isTempUser && (
                      <Button
                        onClick={onUploadDocument}
                        loading={isDownloadingDocuments}
                        style={{ marginRight: 8 }}
                      >
                        서류 업로드
                      </Button>
                    )}
                    <Button
                      onClick={onDownloadAllDocuments}
                      disabled={currentPapRequest.documents.length === 0}
                      loading={isDownloadingDocuments}
                    >
                      일괄 다운로드
                    </Button>
                  </Form.Item>
                  <Table
                    size="small"
                    dataSource={currentPapRequest?.documents}
                    pagination={false}
                    rowKey={(r) => r?.id}
                    columns={[
                      {
                        title: '파일명',
                        dataIndex: 'fileName',
                        key: 'fileName',
                        render: (text, record) => {
                          return (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              <Typography.Text
                                style={{
                                  flex: 1,
                                  textAlign: 'left',
                                }}
                              >
                                <FileImageTwoTone />
                                &nbsp;{text}
                              </Typography.Text>
                              <Button
                                onClick={() => {
                                  if (record)
                                    onDownloadSingleDocument(record.id);
                                }}
                              >
                                <DownloadOutlined />
                              </Button>
                            </div>
                          );
                        },
                      },
                      {
                        title: '등록일시',
                        dataIndex: 'createdAt',
                        key: 'uploadDate',
                        render: (text) =>
                          text ? moment(text).format('YY.MM.DD HH:mm') : '-',
                      },
                    ]}
                  />
                </Form>
                <Divider />
                <Form
                  form={userStatusForm}
                  name="userStatusForm"
                  labelWrap
                  labelCol={{ span: 6 }}
                >
                  <Typography.Title level={5}>유저 상태 정보</Typography.Title>
                  <Form.Item label="현재 상태">
                    <Space>
                      <Input
                        disabled
                        value={PAP_REQUEST_STATUS[currentPapRequest?.status]}
                      />
                      <Button
                        block={false}
                        onClick={() => {
                          setShowHistoryModal(true);
                        }}
                      >
                        사용자 상태 히스토리 보기
                      </Button>
                      <Modal
                        width={800}
                        visible={showHistoryModal}
                        onCancel={() => {
                          setShowHistoryModal(false);
                        }}
                        onOk={() => {
                          setShowHistoryModal(false);
                        }}
                      >
                        <Typography.Title level={5}>
                          상태 히스토리
                        </Typography.Title>
                        <Divider />
                        <Table
                          dataSource={historyData}
                          pagination={false}
                          showHeader={false}
                          rowKey={(r) => r.id}
                          columns={[
                            {
                              key: 'createdAt',
                              dataIndex: 'createdAt',
                              render: (text, record) => (
                                <Typography.Text
                                  style={{
                                    color:
                                      record.modifierId === userId
                                        ? '#1382E9'
                                        : undefined,
                                  }}
                                >
                                  {moment(text).format('YYYY-MM-DD HH:mm')}
                                </Typography.Text>
                              ),
                            },
                            {
                              key: 'modifierEmail',
                              dataIndex: 'modifierEmail',
                              render: (text, record) => (
                                <Typography.Text
                                  style={{
                                    color:
                                      record.modifierId === userId
                                        ? '#1382E9'
                                        : undefined,
                                  }}
                                >
                                  {text}
                                </Typography.Text>
                              ),
                            },
                            {
                              key: 'log',
                              dataIndex: 'log',
                              render: (text, record) => (
                                <Typography.Text
                                  style={{
                                    color:
                                      record.modifierId === userId
                                        ? '#1382E9'
                                        : undefined,
                                  }}
                                >
                                  {text}
                                </Typography.Text>
                              ),
                            },
                          ]}
                        />
                      </Modal>
                    </Space>
                  </Form.Item>
                  <Form.Item dependencies={['status']}>
                    {({ getFieldValue }) => (
                      <Form.Item
                        label="담당자 안내"
                        name="adminMemo"
                        initialValue={currentPapRequest?.adminMemo}
                        tooltip="담당자 안내는 앱 내 PAP 신청 상태 페이지에 노출됩니다."
                        style={{ marginBottom: 0 }}
                      >
                        <Input.TextArea
                          placeholder="예) 수납 영수증의 숫자 구분이 어렵습니다. 선명한 사진으로 다시 업로드해주세요."
                          autoSize
                          disabled={
                            !STATUS_WITH_PUSH.includes(
                              getFieldValue('status'),
                            ) || getFieldValue('status') === 'refund_complete'
                          }
                          onChange={(e) => {
                            setPreviousMemo(e.target.value);
                          }}
                        />
                      </Form.Item>
                    )}
                  </Form.Item>
                  <Form.Item label="상태 변경하기" name="status">
                    <Select
                      onChange={(v) => {
                        if (v === 'refund_complete') {
                          userStatusForm.setFieldsValue({
                            adminMemo:
                              '지원금이 입금되었습니다. 등록된 계좌를 확인해 주세요.',
                          });
                        } else {
                          userStatusForm.setFieldsValue({
                            adminMemo: previousMemo,
                          });
                        }
                      }}
                    >
                      {Object.keys(PAP_REQUEST_STATUS).map((key) => (
                        <Select.Option
                          value={key}
                          key={key}
                          disabled={!PAP_REQUEST_STATUS_EDITABLE[key]}
                        >
                          {PAP_REQUEST_STATUS[key]}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <ul style={{ paddingInlineStart: 20, marginTop: -20 }}>
                    <li>
                      저장 : 앱 내 PAP 신청 상태 페이지에서 담당자 안내와
                      상태값만 변경됩니다.
                    </li>
                    <li>
                      저장 + 푸시 : 앱 내 PAP 신청 상태 페이지에서 담당자 안내와
                      상태 변경 및 푸시가 발송됩니다.
                      <a
                        href="https://docs.google.com/spreadsheets/d/1DHMPPqle7IpoQde4iGJOHtsnimsFNUueOhDUOisY4u0/edit?gid=598816018#gid=598816018"
                        target="_blank"
                        rel="noreferrer"
                      >
                        (푸시 문구 확인하기)
                      </a>
                    </li>
                  </ul>
                  <Form.Item shouldUpdate>
                    {() => {
                      return (
                        <>
                          <Button
                            type="primary"
                            htmlType="submit"
                            style={{
                              float: 'right',
                              marginTop: 8,
                              marginLeft: 8,
                            }}
                            onClick={() => {
                              if (window.confirm('변경사항을 저장할까요?')) {
                                onSave(userStatusForm.getFieldsValue(), false);
                                userStatusForm.submit();
                              }
                            }}
                            loading={formSaving}
                          >
                            저장
                          </Button>
                          <Button
                            type="primary"
                            style={{ float: 'right', marginTop: 8 }}
                            disabled={
                              !STATUS_WITH_PUSH.includes(
                                userStatusForm.getFieldValue('status'),
                              )
                            }
                            onClick={() => {
                              if (
                                window.confirm(
                                  '변경사항을 저장하고 사용자에게 푸시 알림을 전송할까요?',
                                )
                              ) {
                                onSave(userStatusForm.getFieldsValue(), true);
                                userStatusForm.submit();
                              }
                            }}
                            loading={formSaving}
                          >
                            저장 & 푸시 발송
                          </Button>
                        </>
                      );
                    }}
                  </Form.Item>
                </Form>
                <Divider />
                <Form
                  form={hospitalForm}
                  name="hospitalForm"
                  labelWrap
                  labelCol={{ span: 4 }}
                  onFinish={(v) => {
                    onSave({
                      doctorInfo: v,
                    });
                  }}
                >
                  <Typography.Title level={5}>병원 정보</Typography.Title>
                  <Form.Item label="병원" name="hospital">
                    <Input />
                  </Form.Item>
                  <Form.Item label="진료과" name="specialty">
                    <Input />
                  </Form.Item>
                  <Form.Item label="주치의" name="doctor">
                    <Input />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      style={{ float: 'right' }}
                      htmlType="submit"
                      loading={formSaving}
                    >
                      저장
                    </Button>
                  </Form.Item>
                </Form>
                <Divider />
                <Form
                  form={bancAccountForm}
                  name="bancAccountForm"
                  labelWrap
                  labelCol={{ span: 8 }}
                  onFinish={(v) => {
                    onSave({
                      refundInfo: v,
                    });
                  }}
                >
                  <Typography.Title level={5}>환급 계좌 정보</Typography.Title>
                  <Form.Item
                    label="계좌 본인 여부"
                    labelWrap
                    labelCol={{ span: 8 }}
                  >
                    <Input disabled value={isNokAccount ? '대리인' : '본인'} />
                  </Form.Item>
                  <Form.Item label="예금주" name="holder">
                    <Input />
                  </Form.Item>
                  <Form.Item label="은행명" name="bank">
                    <Input />
                  </Form.Item>
                  <Form.Item label="계좌번호" name="number">
                    <Input />
                  </Form.Item>
                  {isNokAccount && (
                    <Form.Item label="대리인 휴대폰 번호" name="phone">
                      <Input />
                    </Form.Item>
                  )}
                  <Form.Item>
                    <Button
                      type="primary"
                      style={{ float: 'right' }}
                      htmlType="submit"
                      loading={formSaving}
                    >
                      저장
                    </Button>
                  </Form.Item>
                </Form>
                <Divider />
                <Form
                  form={documentForm}
                  name="documentForm"
                  labelWrap
                  labelCol={{ span: 8 }}
                  onFinish={(v) => {
                    const formData = { ...v };
                    Object.keys(formData).forEach((key) => {
                      if (
                        typeof formData[key] === 'string' &&
                        formData[key].length === 0
                      ) {
                        delete formData[key];
                      }
                      if (
                        typeof formData[key] === 'object' &&
                        formData[key].value?.length === 0
                      ) {
                        delete formData[key];
                      }
                    });
                    onSave({
                      formData: formatMomentValues(formData),
                    });
                  }}
                >
                  <Typography.Title level={5}>서류 정보</Typography.Title>
                  {documentSchema.map((col) => renderDocumentSchema(col))}
                  <Button
                    type="primary"
                    style={{ float: 'right', marginTop: 8, marginBottom: 24 }}
                    htmlType="submit"
                    loading={formSaving}
                  >
                    저장
                  </Button>
                </Form>
                <Divider />
                <Typography.Title level={5}>
                  서류 정보 데이터 확인하기
                </Typography.Title>
                <Table
                  columns={overViewColumns}
                  dataSource={overViewData}
                  size="small"
                  bordered
                  pagination={false}
                  locale={{ emptyText: <></> }}
                />
              </>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}>
                신청한 PAP가 없습니다.
              </Empty>
            )}
          </Col>
        </>
      )}
    </Row>
  );
};

export default UserPapRequest;
