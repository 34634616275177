import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Input, Layout, notification, Switch } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import AntList from '../../components/antList';
import {
  fetchAllPspProjects,
  updatePspProject,
} from '../../../services/pspService';
import PAGE_URL from '../../../assets/pageUrl';
import { paginationCreators } from '../../../store/reducers/pagination.reducer';
import useWindow from '../../../hooks/useWindow';
import { NEW } from '../../../window/util/utils';
import { DownloadOutlined } from '@ant-design/icons';
import { QRCodeSVG } from 'qrcode.react';
import { getShareLinkUrl } from '../../../services/shareLinkService';

const PspProjectList = () => {
  const [pspProjects, setPspProjects] = useState([]);
  const [deployChangeInfo, setDeployChangeInfo] = useState([]);
  const [pageUpdate, setPageUpdate] = useState(1);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { findWindowById, createWindow, destroyWindowById } = useWindow();
  const { pspProjectPagination } = useSelector((state) => {
    return {
      diseaseTypeData: state.diseaseTypeReducer.diseaseTypes.data,
      pspProjectPagination: state.paginationReducer.pspProjectPagination,
    };
  });
  const confirmRef = useRef(() => {});

  const fetchList = useCallback(async () => {
    setLoading(true);
    const result = await fetchAllPspProjects();
    dispatch(
      paginationCreators.setValue((pspProjectPagination.total = result.count)),
    );
    setPspProjects(result.data);
    setLoading(false);
  }, [dispatch, pspProjectPagination]);

  useEffect(() => {
    if (deployChangeInfo.length > 0) {
      Promise.all(
        deployChangeInfo.map((change) => {
          return updatePspProject(change.id, { isApp: change.isApp });
        }),
      ).then((result) => {
        notification.success({
          key: 'deployChange',
          message: '앱 노출 상태를 성공적으로 변경하였습니다.',
        });
        setDeployChangeInfo([]);
        const ids = result.map((r) => r.id);
        setPspProjects(
          pspProjects.map((project) => {
            if (ids.includes(project.id)) {
              return {
                ...project,
                isApp: result.find((r) => r.id === project.id).isApp,
              };
            }
            return project;
          }),
        );
      });
    }
  }, [deployChangeInfo]);

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  const TagWrapper = ({ tag }) => {
    return (
      <div
        className="tagWrapper"
        key={tag}
        style={{
          color: '#333',
        }}
      >
        {tag}
      </div>
    );
  };

  const handleCreateDetailWindow = (pspProject) => {
    console.log('pspProject', pspProject);
    if (findWindowById(pspProject?.id)) {
      alert('이미 편집중인 프로젝트입니다.');
      return;
    }
    createWindow({
      id: pspProject?.id ?? `${NEW}${Date.now()}`,
      dataType: 'pspProject',
    });
  };

  const receiveMessage = useCallback(
    (event) => {
      if (
        event.origin !== window.location.origin ||
        typeof event.data !== 'string'
      )
        return;
      const [command, id] = event.data.split(' ');
      if (command === 'close' && id.length > 0) {
        destroyWindowById(id);
        fetchList();
      }
    },
    [fetchList, destroyWindowById],
  );

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, [receiveMessage]);

  const onChange = (pagination, filters, sorter, extra) => {
    dispatch(
      paginationCreators.setValue(
        (pspProjectPagination.page = pagination?.current),
        (pspProjectPagination.filter = filters),
        (pspProjectPagination.sorter = sorter),
        (pspProjectPagination.total = extra?.currentDataSource?.length),
      ),
    );
  };

  const columns = [
    {
      title: '등록일',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '120px',
      render: (text) => dayjs(text).format('YY-MM-DD'),
      sorter: {
        compare: (a, b) => a?.createdAt?.localeCompare(b?.createdAt),
      },
    },
    {
      title: 'PSP명',
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => (
        <Button
          type="link"
          style={{
            width: '100%',
            textAlign: 'left',
            whiteSpace: 'normal',
          }}
          href={`/${PAGE_URL.PSP_AUDIT}?id=${record.id}`}
          onClick={(e) => {
            e.preventDefault();
            history.push(`/${PAGE_URL.PSP_AUDIT}?id=${record.id}`);
          }}
        >
          {text}
        </Button>
      ),
      sorter: {
        compare: (a, b) => a?.title?.localeCompare(b?.title),
      },
      filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => {
        confirmRef.current = (searchWord) => {
          if (searchWord) setSelectedKeys([searchWord]);
          else clearFilters();
          confirm();
        };
        return <></>;
      },
      filterIcon: () => <></>,
      onFilter: (search, { title, createdAt, diseaseTypeNames }) =>
        [title, createdAt, diseaseTypeNames].some((value) =>
          value?.includes(search),
        ),
    },
    {
      title: 'PSP 내용 수정',
      width: '110px',
      render: (_, record) => (
        <Button onClick={() => handleCreateDetailWindow(record)}>
          수정하기 &gt;
        </Button>
      ),
    },
    {
      title: '질환명',
      dataIndex: 'diseaseTypes',
      key: 'diseaseTypes',
      width: 300,
      render: (diseaseArr) => {
        return (
          <>
            {diseaseArr?.map((disease) => (
              <TagWrapper key={disease.id} tag={disease.krName} />
            ))}
          </>
        );
      },
      sorter: {
        compare: (a, b) =>
          a?.diseaseTypeNames?.localeCompare(b?.diseaseTypeNames),
      },
    },
    {
      title: '신청 중',
      dataIndex: 'requestUserCount',
      key: 'requestUserCount',
      width: '90px',
      sorter: (a, b) => a.requestUserCount - b.requestUserCount,
    },
    {
      title: '신청 완료',
      dataIndex: 'completeUserCount',
      key: 'completeUserCount',
      width: '90px',
      sorter: (a, b) => a.completeUserCount - b.completeUserCount,
    },
    {
      title: '앱 노출',
      dataIndex: 'isApp',
      key: 'isApp',
      width: '80px',
      render: (text, record) => (
        <div>
          <Switch checked={record?.isApp} size="small" />
        </div>
      ),
      filters: [
        {
          text: 'On',
          value: true,
        },
        {
          text: 'Off',
          value: false,
        },
      ],
      onFilter: (value, record) => record.isApp === value,
    },
    {
      title: 'QR 다운로드',
      dataIndex: 'qrLink',
      key: 'qrLink',
      width: '100px',
      render: (text, record) => {
        const shareLink = getShareLinkUrl('psp', record.id);
        return (
          <div>
            <Button
              icon={<DownloadOutlined />}
              onClick={(e) => {
                const svgElement = e.currentTarget.nextSibling;
                if (!shareLink || !svgElement) {
                  notification.error({
                    message: 'QR 코드가 생성되지 않았습니다.',
                    key: 'qrCodeNotGenerated',
                  });
                  return;
                }
                const serialized = new XMLSerializer().serializeToString(
                  svgElement,
                );
                const blob = new Blob([serialized], {
                  type: 'image/svg+xml;charset=utf-8',
                });
                const a = document.createElement('a');
                a.href = URL.createObjectURL(blob);
                a.download = `${record?.title}.svg`;
                a.click();
              }}
            />
            <QRCodeSVG value={shareLink} hidden />
          </div>
        );
      },
    },
  ];

  const { Search } = Input;
  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabData" link={PAGE_URL.PSP} />
        <Layout className="right-layout">
          <TitleBreadcrumb title="운영" subTitle="PSP" className="white-bg" />
          <Content className="site-layout-background contentStyle">
            <Button type="primary" onClick={handleCreateDetailWindow}>
              신규 PSP 등록
            </Button>
            <Search
              placeholder="검색어를 입력해주세요."
              allowClear
              className="searchStyle"
              onSearch={(value) => {
                confirmRef.current(value);
                dispatch(
                  paginationCreators.setValue(
                    (pspProjectPagination.filter.keyword =
                      value.length > 0 ? value : null),
                  ),
                );
              }}
              defaultValue={pspProjectPagination.filter.keyword}
            />
            <div className="searchResult">{`검색결과 ${pspProjectPagination.total}개`}</div>
            <AntList
              loading={loading}
              deployChangeInfo={deployChangeInfo}
              setDeployChangeInfo={setDeployChangeInfo}
              pageUpdate={pageUpdate}
              setPageUpdate={setPageUpdate}
              columns={columns}
              dataSource={pspProjects}
              onChange={onChange}
              pagination={{
                pageSize: 20,
                showSizeChanger: true,
              }}
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default PspProjectList;
