import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  notification,
  Radio,
  Row,
  Select,
} from 'antd';
import moment from 'moment';
import { parse } from 'qs';
import React, { useEffect, useReducer, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useFetch } from '../../../hooks/useRequest';
import {
  createPapPostalUser,
  deletePapPostalUser,
  fetchPapPostalUser,
  fetchValidatePapPostalUserData,
  updatePapPostalUser,
} from '../../../services/papService';
import { diseaseTypeCreators } from '../../../store/reducers/diseaseType.reducer';
import { papProjectCreators } from '../../../store/reducers/papProject.reducer';
import WindowHeader from '../../components/windowHeader';

const initialState = {
  name: '', // 환자 이름, non-null
  birthday: '', // 생년월일, non-null, YYYY-MM-DD
  phone: '', // 연락처, nullable
  gender: '', // 성별, non-null, MALE : 남성 / FEMALE: 여성
  registeredAt: '', // 신청 일시, non-null
  diseaseTypeId: null,
  projectIds: [],
};

const PapPostalUserWindow = () => {
  const [windowId, setWindowId] = useState('');
  const [papId, setPapId] = useState(null);
  const [tempUserId, setTempUserId] = useState(null);
  const [form] = Form.useForm();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [validateCreation, setValidateCreation] = useState(null);

  const dispatch = useDispatch();
  const { diseaseTypeInfo, papProjects } = useSelector((state) => {
    return {
      papProjects: state.papProjectReducer?.papProject?.data?.data,
      diseaseTypeInfo: state.diseaseTypeReducer.diseaseTypes.data
        ? state.diseaseTypeReducer.diseaseTypes.data.map(({ id, krName }) => ({
            id,
            krName,
          }))
        : null,
    };
  }, shallowEqual);

  const { data: PapPostalUserData, call: fetchPapPostalUserData } = useFetch(
    null,
    fetchPapPostalUser,
    tempUserId,
  );
  const { search } = useLocation();

  const setupBeforeUnloadListener = (data) => {
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      return window?.opener?.postMessage(data, '/');
    });
  };

  useEffect(() => {
    const params = parse(search, {
      ignoreQueryPrefix: true,
    });
    setWindowId(params.id ? params.id : params.new);
    dispatch(diseaseTypeCreators.fetchAllDiseaseTypes.request());
    dispatch(papProjectCreators.fetchAllPapProjects.request());
    if (params.papId) {
      setPapId(params.papId);
      form.setFieldValue('projectIds', [params.papId]);
    }
    if (params.id) {
      setTempUserId(params.id);
    }
    setupBeforeUnloadListener(`close ${windowId}`);
  }, [search, windowId]);

  useEffect(() => {
    if (tempUserId) {
      fetchPapPostalUserData();
      setValidateCreation('success');
    }
  }, [fetchPapPostalUserData, tempUserId, setValidateCreation]);

  useEffect(() => {
    if (PapPostalUserData) {
      form.setFieldsValue({
        ...PapPostalUserData,
        birthday: PapPostalUserData.birthday
          ? moment(PapPostalUserData.birthday)
          : null,
        projectIds: PapPostalUserData?.projects?.map((project) => project.id),
        diseaseTypeId: PapPostalUserData?.diseaseType?.id,
        phone: PapPostalUserData?.phone?.replace(/[^0-9]/g, ''),
      });
    }
  }, [PapPostalUserData]);

  const handleSubmit = async (values) => {
    try {
      if (!validateCreation === 'success') {
        alert(
          !validateCreation
            ? '환자 중복 확인을 해주세요.'
            : '이미 등록된 유저입니다. 유저 정보를 확인 후 등록해 주세요.',
        );
        return;
      }
      if (window.confirm('저장하시겠습니까?')) {
        setSubmitLoading(true);
        const REQ_BODY = {
          ...values,
          birthday: moment(values.birthday).format('YYYY-MM-DD'),
          registeredAt: moment(values.registeredAt).toISOString(),
        };
        if (tempUserId) {
          await updatePapPostalUser(tempUserId, REQ_BODY);
        } else {
          await createPapPostalUser(values);
        }
        alert('저장되었습니다.');
        window.close();
      }
    } catch (error) {
      message.error('저장에 실패했습니다.');
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleDelete = async () => {
    if (
      window.confirm(
        '해당 환자를 삭제하시겠습니까?\n기존에 등록된 약관 동의 항목, 서류 업로드 항목이 모두 삭제됩니다.',
      )
    ) {
      await deletePapPostalUser(papId);
      alert('삭제되었습니다.');
      window.close();
    }
  };

  const handleCheckDuplicate = async () => {
    const values = form.getFieldsValue();
    if (!values.name || !values.birthday) {
      alert('환자 이름과 생년월일을 입력해주세요.');
      return;
    }
    try {
      const res = await fetchValidatePapPostalUserData(
        values.name,
        values.birthday.format('YYYY-MM-DD'),
      );

      if (res?.isAvailable) {
        setValidateCreation('success');
      } else {
        if (res?.message?.includes('Already Exists User')) {
          setValidateCreation(res?.message?.split(': ')[1]);
        } else if (res?.message === 'Already Exists Pap Temp User') {
          setValidateCreation('temp');
        }
      }
    } catch (error) {
      alert('환자 중복 확인에 실패했습니다.');
    }
  };

  return (
    <>
      <WindowHeader title={`우편 접수 환자 ${tempUserId ? '수정' : '추가'}`} />
      <Row span={24} style={{ padding: 16 }}>
        <Col span={24}>
          <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            form={form}
            onFinish={handleSubmit}
            onFinishFailed={(error) => {
              notification.error({
                key: 'onFailed',
                message: '저장에 실패했습니다. 필수 항목을 확인해주세요.',
              });
            }}
            initialValues={initialState}
          >
            <Form.Item
              label="환자 이름"
              name="name"
              tooltip="우편 접수 환자 추가 시 관계는 ‘환자'로만 등록 가능합니다. 보호자, 대리인이 아닌 ‘환자 이름'을 입력해 주세요. "
              rules={[
                {
                  required: true,
                  message: '환자 이름을 확인해주세요.',
                },
              ]}
            >
              <Input placeholder="예) 김레어" />
            </Form.Item>
            <Form.Item
              label="생년월일"
              name="birthday"
              rules={[{ required: true, message: '생년월일을 확인해주세요.' }]}
              getValueProps={(v) => ({
                value: v ? moment(v) : null,
              })}
            >
              <DatePicker format="YYYY-MM-DD" />
            </Form.Item>

            {!tempUserId && (
              <Form.Item
                wrapperCol={{
                  offset: 4,
                  span: 20,
                }}
              >
                <Row gutter={4}>
                  <Col>
                    <Button
                      type="primary"
                      htmlType="button"
                      style={{ width: 100 }}
                      onClick={handleCheckDuplicate}
                    >
                      중복 확인
                    </Button>
                  </Col>
                  {validateCreation && (
                    <Col
                      style={{
                        color: validateCreation === 'success' ? 'blue' : 'red',
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: 8,
                      }}
                    >
                      {validateCreation === 'success'
                        ? '우편 접수 환자로 신규 등록 가능합니다.'
                        : `${
                            validateCreation === 'temp'
                              ? '우편접수'
                              : `${validateCreation}으`
                          }로 이미 등록된 유저입니다. 유저 정보를 확인 후 등록해 주세요.`}
                    </Col>
                  )}
                </Row>
              </Form.Item>
            )}

            <Form.Item label="연락처" name="phone">
              <Input
                placeholder="예) 01023453456"
                onChange={(e) => {
                  form.setFieldValue(
                    'phone',
                    e.target.value?.replace(/[^0-9]/g, ''),
                  );
                }}
              />
            </Form.Item>

            <Form.Item
              label="성별"
              name="gender"
              rules={[{ required: true, message: '성별을 확인해주세요.' }]}
            >
              <Radio.Group>
                <Radio value="MALE">남성</Radio>
                <Radio value="FEMALE">여성</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="질환"
              name="diseaseTypeId"
              rules={[
                {
                  required: true,
                  message: '질환을 확인해주세요',
                },
              ]}
            >
              <Select
                allowClear
                style={{ width: '100%' }}
                placeholder="질환을 선택해주세요"
                filterOption={(input, option) =>
                  option.props.value
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0 ||
                  option.props.key.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                }
              >
                {diseaseTypeInfo?.map((diseaseType) => (
                  <Select.Option
                    key={diseaseType?.krName}
                    id={diseaseType?.krName}
                    value={diseaseType?.id}
                  >
                    {diseaseType.krName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="신청 일시(우편 접수 일시)"
              name="registeredAt"
              rules={[{ required: true, message: '신청 일시를 확인해주세요.' }]}
              getValueProps={(v) => ({
                value: v ? moment(v) : null,
              })}
            >
              <DatePicker showTime format="YYYY-MM-DD HH:mm" />
            </Form.Item>

            <Form.Item
              label="신청한 PAP"
              name="projectIds"
              tooltip="우편으로 접수한 PAP명을 선택해 주세요."
              rules={[
                {
                  required: true,
                  message: '신청한 PAP명을 확인해주세요.',
                },
              ]}
            >
              <Select
                allowClear
                style={{ width: '100%' }}
                mode="multiple"
                placeholder="PAP 프로그램명 검색 (예: 코셀루고), 다중 선택 가능"
                filterOption={(input, option) =>
                  option.props.value
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0 ||
                  option.props.key.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                }
              >
                {papProjects?.map((pap) => (
                  <Select.Option key={pap?.id} id={pap?.id} value={pap?.id}>
                    {pap.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 4,
                span: 20,
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: 100 }}
                loading={submitLoading}
              >
                저장
              </Button>
              {papId && (
                <Button
                  danger
                  htmlType="button"
                  style={{ width: 100, marginLeft: 8 }}
                  onClick={handleDelete}
                >
                  삭제
                </Button>
              )}
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default PapPostalUserWindow;
